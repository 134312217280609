var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item" }, [
    _vm.contentType === "lawSearch"
      ? _c(
          "div",
          {
            on: {
              click: function ($event) {
                _vm.showText = false
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c(
                  "van-swipe-cell",
                  {
                    scopedSlots: _vm._u(
                      [
                        !_vm.isPreview
                          ? {
                              key: "right",
                              fn: function () {
                                return [
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "primary",
                                      text: "上移",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.upMov(
                                          "lawList",
                                          "lawReports"
                                        )
                                      },
                                    },
                                  }),
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "primary",
                                      text: "下移",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downMove(
                                          "lawList",
                                          "lawReports"
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        !_vm.isPreview
                          ? {
                              key: "left",
                              fn: function () {
                                return [
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "danger",
                                      text: "删 除",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickDel(
                                          "lawList",
                                          "lawReports"
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("div", { staticClass: "title-line" }, [
                      _c(
                        "span",
                        {
                          staticClass: "text case-title",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.showText = !_vm.showText
                            },
                          },
                        },
                        [
                          _vm.item.timeliness !== "现行有效" &&
                          _vm.item.timeliness !== ""
                            ? _c("span", { staticClass: "CanNot" }, [
                                _vm._v(" " + _vm._s(_vm.item.timeliness) + " "),
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.item.title) + " "),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showText && _vm.item.law_id,
                              expression: "showText && item.law_id",
                            },
                          ],
                          staticClass: "show-all",
                          on: {
                            click: function ($event) {
                              return _vm.toLawDetail(_vm.item)
                            },
                          },
                        },
                        [_vm._v(" 显示全文 ")]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm.item.hit_datas && _vm.item.hit_datas.content
              ? _vm._l(_vm.item.hit_datas.content, function (iter, num) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: iter.value,
                          expression: "iter.value",
                        },
                      ],
                      key: num,
                      staticClass: "zkcontent",
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            `<span style='font-weight:bold;'>${
                              iter.name !== "全文"
                                ? _vm.item.hit_datas.catlog[num] + "\t"
                                : ""
                            }</span>${iter.value}`
                          ),
                        },
                      }),
                    ]
                  )
                })
              : _vm._e(),
            _c("div", { staticClass: "showrow" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.item.publish_machinery) +
                    _vm._s(_vm.item.pub_date ? ` / ${_vm.item.pub_date}` : "") +
                    _vm._s(
                      _vm.item.potency_level
                        ? ` / ${_vm.item.potency_level}`
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
          ],
          2
        )
      : _vm._e(),
    _vm.contentType === "knowledgeSearch"
      ? _c(
          "div",
          {
            staticStyle: { overflow: "hidden", "padding-bottom": "10px" },
            on: {
              click: function ($event) {
                _vm.showText = false
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c(
                  "van-swipe-cell",
                  {
                    scopedSlots: _vm._u(
                      [
                        !_vm.isPreview
                          ? {
                              key: "right",
                              fn: function () {
                                return [
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "primary",
                                      text: "上移",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.upMov(
                                          "knowledgeList",
                                          "knowledgeReports"
                                        )
                                      },
                                    },
                                  }),
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "primary",
                                      text: "下移",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downMove(
                                          "knowledgeList",
                                          "knowledgeReports"
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        !_vm.isPreview
                          ? {
                              key: "left",
                              fn: function () {
                                return [
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "danger",
                                      text: "删 除",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickDel(
                                          "knowledgeList",
                                          "knowledgeReports"
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("div", { staticClass: "title-line" }, [
                      _c("span", {
                        staticClass: "text case-title",
                        domProps: { innerHTML: _vm._s(_vm.item.title) },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.showText = !_vm.showText
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showText,
                              expression: "showText",
                            },
                          ],
                          staticClass: "show-all",
                          on: {
                            click: function ($event) {
                              return _vm.toKnowledgeDetail(
                                _vm.item.url,
                                _vm.item.source
                              )
                            },
                          },
                        },
                        [_vm._v(" 显示全文 ")]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._l(_vm.item.content, function (iter, num) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: iter.value,
                      expression: "iter.value",
                    },
                  ],
                  key: num,
                  staticClass: "zkcontent",
                },
                [_c("span", { domProps: { innerHTML: _vm._s(iter.value) } })]
              )
            }),
            _c("div", { staticClass: "showrow" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  _vm._s(_vm.item.origin) + _vm._s(" - " + _vm.item.pub_date)
                ),
              ]),
            ]),
          ],
          2
        )
      : _vm._e(),
    _vm.contentType === "criterionSearch"
      ? _c(
          "div",
          {
            staticStyle: { overflow: "hidden", "padding-bottom": "10px" },
            on: {
              click: function ($event) {
                _vm.showText = false
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c(
                  "van-swipe-cell",
                  {
                    scopedSlots: _vm._u(
                      [
                        !_vm.isPreview
                          ? {
                              key: "right",
                              fn: function () {
                                return [
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "primary",
                                      text: "上移",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.upMov(
                                          "criterionList",
                                          "criterionReports"
                                        )
                                      },
                                    },
                                  }),
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "primary",
                                      text: "下移",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downMove(
                                          "criterionList",
                                          "criterionReports"
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        !_vm.isPreview
                          ? {
                              key: "left",
                              fn: function () {
                                return [
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "danger",
                                      text: "删 除",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickDel(
                                          "criterionList",
                                          "criterionReports"
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("div", { staticClass: "title-line" }, [
                      _c(
                        "span",
                        {
                          staticClass: "text case-title",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.showText = !_vm.showText
                            },
                          },
                        },
                        [
                          _vm.item.stdStatus && _vm.item.stdStatus !== "现行"
                            ? _c("span", { staticClass: "CanNot" }, [
                                _vm._v(" " + _vm._s(_vm.item.stdStatus) + " "),
                              ])
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(_vm.item.id + " ") +
                              _vm._s(_vm.item.stdTitle || _vm.item.title) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showText,
                              expression: "showText",
                            },
                          ],
                          staticClass: "show-all",
                          on: {
                            click: function ($event) {
                              return _vm.toCriterionDetail(_vm.item.url)
                            },
                          },
                        },
                        [_vm._v(" 显示全文 ")]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "showrow" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.item.department ? _vm.item.department + " " : ""
                    ) +
                    _vm._s(
                      _vm.item.publishDate ? _vm.item.publishDate + " " : ""
                    ) +
                    _vm._s(_vm.item.execDate ? _vm.item.execDate : "") +
                    " "
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.contentType === "agreementSearch"
      ? _c(
          "div",
          {
            staticStyle: { overflow: "hidden", "padding-bottom": "10px" },
            on: {
              click: function ($event) {
                _vm.showText = false
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c(
                  "van-swipe-cell",
                  {
                    scopedSlots: _vm._u(
                      [
                        !_vm.isPreview
                          ? {
                              key: "right",
                              fn: function () {
                                return [
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "primary",
                                      text: "上移",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.upMov(
                                          "agreementList",
                                          "agreementReports"
                                        )
                                      },
                                    },
                                  }),
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "primary",
                                      text: "下移",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downMove(
                                          "agreementList",
                                          "agreementReports"
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        !_vm.isPreview
                          ? {
                              key: "left",
                              fn: function () {
                                return [
                                  _c("van-button", {
                                    attrs: {
                                      square: "",
                                      type: "danger",
                                      text: "删 除",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickDel(
                                          "agreementList",
                                          "agreementReports"
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("div", { staticClass: "title-line" }, [
                      _c(
                        "span",
                        {
                          staticClass: "text case-title",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.showText = !_vm.showText
                            },
                          },
                        },
                        [
                          _vm.item.isRecommend
                            ? _c("span", { staticClass: "CanNot" }, [
                                _vm._v(" 推荐 "),
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.item.title) + " "),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "showrow" }, [
              _c("div", { staticClass: "text" }, [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.item.publisher && _vm.item.publisher.nickname)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.item.createTime && _vm.fmTime(_vm.item.createTime)
                      )
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.item.price === 0 || _vm.item.price === "0"
                          ? "免费"
                          : _vm.item.price + "元"
                      )
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.item.commentCount !== ""
                        ? " 下载量: (" + _vm.item.commentCount + ") "
                        : ""
                    )
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }