<template>
  <div class="item">
    <!-- 法律 -->
    <div v-if="contentType === 'lawSearch'" @click="showText = false">
      <div class="title">
        <van-swipe-cell>
          <div class="title-line">
            <span class="text case-title" @click.stop="showText = !showText">
              <span
                class="CanNot"
                v-if="item.timeliness !== '现行有效' && item.timeliness !== ''"
              >
                {{ item.timeliness }}
              </span>
              {{ item.title }}
            </span>
            <div
              class="show-all"
              v-show="showText && item.law_id"
              @click="toLawDetail(item)"
            >
              显示全文
            </div>
          </div>
          <template #right v-if="!isPreview">
            <van-button
              square
              type="primary"
              text="上移"
              @click="upMov('lawList', 'lawReports')"
            />
            <van-button
              square
              type="primary"
              text="下移"
              @click="downMove('lawList', 'lawReports')"
            />
          </template>
          <template #left v-if="!isPreview">
            <van-button
              square
              type="danger"
              @click="clickDel('lawList', 'lawReports')"
              text="删 除"
            />
          </template>
        </van-swipe-cell>
      </div>
      <template v-if="item.hit_datas && item.hit_datas.content">
        <div
          class="zkcontent"
          v-for="(iter, num) in item.hit_datas.content"
          v-show="iter.value"
          :key="num"
        >
          <span
            v-html="
              `<span style='font-weight:bold;'>${
                iter.name !== '全文' ? item.hit_datas.catlog[num] + '\t' : ''
              }</span>${iter.value}`
            "
          ></span>
        </div>
      </template>
      <div class="showrow">
        <div class="text">
          {{ item.publish_machinery
          }}{{ item.pub_date ? ` / ${item.pub_date}` : ''
          }}{{ item.potency_level ? ` / ${item.potency_level}` : '' }}
        </div>
      </div>
    </div>
    <!-- 知识 -->
    <div
      style="overflow:hidden;padding-bottom:10px;"
      v-if="contentType === 'knowledgeSearch'"
      @click="showText = false"
    >
      <div class="title">
        <van-swipe-cell>
          <div class="title-line">
            <span
              class="text case-title"
              @click.stop="showText = !showText"
              v-html="item.title"
            >
            </span>
            <div
              class="show-all"
              v-show="showText"
              @click="toKnowledgeDetail(item.url, item.source)"
            >
              显示全文
            </div>
          </div>
          <template #right v-if="!isPreview">
            <van-button
              square
              type="primary"
              text="上移"
              @click="upMov('knowledgeList', 'knowledgeReports')"
            />
            <van-button
              square
              type="primary"
              text="下移"
              @click="downMove('knowledgeList', 'knowledgeReports')"
            />
          </template>
          <template #left v-if="!isPreview">
            <van-button
              square
              type="danger"
              @click="clickDel('knowledgeList', 'knowledgeReports')"
              text="删 除"
            />
          </template>
        </van-swipe-cell>
      </div>
      <div
        class="zkcontent"
        v-for="(iter, num) in item.content"
        v-show="iter.value"
        :key="num"
      >
        <span v-html="iter.value"></span>
      </div>
      <div class="showrow">
        <div class="text">{{ item.origin }}{{ ' - ' + item.pub_date }}</div>
      </div>
    </div>
    <!-- 标准 -->
    <div
      style="overflow:hidden;padding-bottom:10px;"
      v-if="contentType === 'criterionSearch'"
      @click="showText = false"
    >
      <div class="title">
        <van-swipe-cell>
          <div class="title-line">
            <span class="text case-title" @click.stop="showText = !showText">
              <span
                class="CanNot"
                v-if="item.stdStatus && item.stdStatus !== '现行'"
              >
                {{ item.stdStatus }}
              </span>
              {{ item.id + ' ' }}{{ item.stdTitle || item.title }}
            </span>
            <div
              class="show-all"
              v-show="showText"
              @click="toCriterionDetail(item.url)"
            >
              显示全文
            </div>
          </div>
          <template #right v-if="!isPreview">
            <van-button
              square
              type="primary"
              text="上移"
              @click="upMov('criterionList', 'criterionReports')"
            />
            <van-button
              square
              type="primary"
              text="下移"
              @click="downMove('criterionList', 'criterionReports')"
            />
          </template>
          <template #left v-if="!isPreview">
            <van-button
              square
              type="danger"
              @click="clickDel('criterionList', 'criterionReports')"
              text="删 除"
            />
          </template>
        </van-swipe-cell>
      </div>
      <div class="showrow">
        <div class="text">
          {{ item.department ? item.department + ' ' : ''
          }}{{ item.publishDate ? item.publishDate + ' ' : ''
          }}{{ item.execDate ? item.execDate : '' }}
        </div>
      </div>
    </div>
    <!-- 合同 -->
    <div
      style="overflow:hidden;padding-bottom:10px;"
      v-if="contentType === 'agreementSearch'"
      @click="showText = false"
    >
      <div class="title">
        <van-swipe-cell>
          <div class="title-line">
            <span class="text case-title" @click.stop="showText = !showText">
              <span class="CanNot" v-if="item.isRecommend">
                推荐
              </span>
              {{ item.title }}
            </span>
            <!-- <div
              class="show-all"
              v-show="showText"
              @click="toCriterionDetail(item.url)"
            >
              显示全文
            </div> -->
          </div>
          <template #right v-if="!isPreview">
            <van-button
              square
              type="primary"
              text="上移"
              @click="upMov('agreementList', 'agreementReports')"
            />
            <van-button
              square
              type="primary"
              text="下移"
              @click="downMove('agreementList', 'agreementReports')"
            />
          </template>
          <template #left v-if="!isPreview">
            <van-button
              square
              type="danger"
              @click="clickDel('agreementList', 'agreementReports')"
              text="删 除"
            />
          </template>
        </van-swipe-cell>
      </div>
      <div class="showrow">
        <div class="text">
          <span> {{ item.publisher && item.publisher.nickname }}</span>
          <span> {{ item.createTime && fmTime(item.createTime) }}</span>
          <span>
            {{
              item.price === 0 || item.price === '0'
                ? '免费'
                : item.price + '元'
            }}</span
          >
          <span>{{
            item.commentCount !== ''
              ? ' 下载量: (' + item.commentCount + ') '
              : ''
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'law-item',
  props: {
    item: Object,
    contentType: String,
    itemindex: Number,
    isPreview: Boolean,
  },
  data() {
    return {
      showText: false,
    }
  },
  methods: {
    // 格式化时间戳
    fmTime(unixtime) {
      var date = new Date(unixtime)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var timeStr = y + '-' + m + '-' + d + ' 发布'
      return timeStr
    },
    clickDel(listType, reportType) {
      this.$emit('delItem', {
        listType,
        reportType,
        id: this.item.id,
        showConfirm: true,
        index: this.itemindex,
      })
    },
    upMov(listType, reportType) {
      this.$emit('move', {
        direction: 'up',
        listType,
        reportType,
        item: this.item,
        index: this.itemindex,
      })
    },
    downMove(listType, reportType) {
      this.$emit('move', {
        direction: 'down',
        listType,
        reportType,
        item: this.item,
        index: this.itemindex,
      })
    },
    toLawDetail(item) {
      this.$emit('toDetail', item)
    },
    toCriterionDetail(url) {
      this.$emit('toDetail', url)
    },
    toKnowledgeDetail(url, source) {
      this.$emit('toDetail', { url, source })
    },
  },
}
</script>

<style scope lang="stylus">
.item
  position relative
  & .title
    width 100%
    padding 10px
    overflow hidden
    cursor pointer
    display flex
    flex-direction row
    .title-line
      display flex
      justify-content space-between
      align-items center
      position relative
    .show-all
      position absolute
      right 0
      top 0
      z-index 9
      background rgba(254,254,254,0.8)
      border-radius 5px
      width 40px
      height 40px
      color #409eff
      text-align center
      font-size 14px
      line-height 20px
    .case-title
      flex 1
      min-height 42px
      display inline-block
      width calc(100vw - 20px)
    & .CanNot
      height 21px
      display: inline-block;
      box-sizing border-box
      background #ff9c39
      color #fff
      line-height 21px
      padding 0 5px
      border-radius 5px
  & .showrow
    width 100%
    padding 10px
    padding-top 0
    overflow hidden
    & .text
      font-size 12px
      color #999999
      font-weight normal
      float left
    & .open
      float left
      font-size 12px
      color #999999
      font-weight normal
      overflow hidden
      margin-right 10px
      cursor pointer
      & i
        font-size 14px
      & .tip
        overflow hidden
        padding 3px 5px 3px
        background-color #ff9c39
        font-size 14px
        color white
        border-radius 3px
  & .zkcontent
    width 100%
    padding 0 10px
    margin-bottom 10px
    & h4
      font-size 14px
    & span
      font-size 12px
& .more
  color #666666
  font-size 16px
  text-align center
  margin 20px 0px 20px 0px
</style>
