<template>
  <div class="report" ref="report">
    <div v-if="!editMode">
      <!-- 头部插槽 -->
      <div v-show="!detailshow.show && !detailshow.flag">
        <fb-header slot="header" fixed>
          <div slot="left" class="head-left">
            <fb-button icon="back" @click="handleGoBack" size="small" type="primary"></fb-button>
            <!-- <div class="user-time">
              <span>{{ userName }}的检索报告</span>
              <span>&nbsp;&nbsp;</span>
              <span>{{ dayTime }}</span>
            </div> -->
            <div class="collect-box">
              <span>收集箱</span>
              <span style="color: red">{{ reportCount }}</span>
            </div>

            <div class="switch-collect" v-if="reportId" @click="handleSwitch">新收集</div>
            <div class="relate-case">{{ relateCaseName }}</div>
          </div>
          <div slot="right">
            <div class="header-menu">
              <!-- <div class="menu-text" @click="handleAddFirstPage">
                插入封面
              </div> -->
              <!-- <div class="menu-text" @click="editMode = true">
                <span>编辑</span>
              </div> -->
              <!-- <div class="menu-text" @click="handleSave">
                保存
              </div> -->
              <div class="menu-text" @click="handleShowHistory">历史</div>
              <div class="menu-text" @click="handleRelateCase">
                <span>案件</span>
              </div>
              <div class="menu-text" @click="handleShare" v-if="!$route.query.isMiniApp">
                <span>分享</span>
              </div>
              <div v-if="showShare" class="menu-text" @click="toShare">
                <span>个人分享</span>
              </div>
              <div class="menu-text morelist">
                <span @click="showMenuList = !showMenuList" v-if="!$route.query.isMiniApp">更多</span>
                <div class="menu-list" v-show="showMenuList">
                  <span @click="clickDownload" v-if="!$route.query.isMiniApp">{{ isIOS ? '预览' : '下载' }}</span>
                  <!-- <span @click="handleShowHistory">历史</span> -->
                  <span @click="handleSaveElse">另存为</span>
                </div>
              </div>
            </div>
          </div>
        </fb-header>
        <div class="search-report" ref="searchReport">
          <div ref="reportContainer" id="reportContainer" class="report-container">
            <div class="print-fengmian" id="fengmian" v-if="hasFirstPage" @click.stop="blurInput">
              <div class="fengmian-logo"></div>
              <div class="fengmian-lawfirm center">
                <span v-show="!editLawFirmName" @click.stop="clickLawFirmName">{{ lawFirmName.length > 10 ? lawFirmName.substr(0, 10) + '...' : lawFirmName }}</span>
                <van-field ref="lawFirmNameInput" v-show="editLawFirmName" @blur="editLawFirmName = false" v-model="lawFirmName" placeholder="请填写内容" />
              </div>
              <div class="fengmian-report">
                <span>检</span>
                <span>索</span>
                <span>报</span>
                <span>告</span>
              </div>
              <div class="fengmian-userinfo">
                <div class="name">
                  <span v-show="!editUserName" @click.stop="clickUserName">{{ userName }}</span>
                  <van-field ref="userNameInput" v-show="editUserName" @blur="editUserName = false" v-model="userName" />
                </div>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <div class="time">{{ dayTime }}</div>
              </div>
            </div>
            <div class="print-body" id="reportBody">
              <div class="add-content" id="addContent">
                <div v-if="showAddText" @click.stop="showAddText = false" class="add-text">添加内容</div>
                <div class="add-area" v-else>
                  <div style="padding: 10px" v-show="showAreaText" @click.stop="focusTextInput">
                    {{ wordReport }}
                  </div>
                  <div v-show="!showAreaText">
                    <van-field ref="textInput" autosize type="textarea" @blur="showAreaText = true" v-model="wordReport" placeholder="请填写内容" />
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between; align-items: center" v-show="judgeList.length">
                <h3 id="judge" @click.stop="clickJudge">判例</h3>
                <div class="mul-btns" v-if="!$route.query.isMiniApp">
                  <span @click="handleMulDownload()">批量下载</span>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span @click="handleMulShare('judgeReports')">批量分享</span>
                </div>
              </div>
              <div class="item van-hairline--top-bottom" v-for="(item, index) in judgeList" :key="item.doc_id" :id="item.doc_id">
                <judgementItem ref="judgeitem" :itemindex="index" :item="item" :isPreview="false" @move="handleMove" @delItem="handleDelItem" @toDetail="toJudgeDetail" />
              </div>
              <div style="display: flex; justify-content: space-between; align-items: center" v-show="lawList.length">
                <h3 id="law" @click.stop="clickLaw">法律</h3>
                <div class="mul-btns" v-if="!$route.query.isMiniApp">
                  <span @click="handleMulShare('lawReports')">批量分享</span>
                </div>
              </div>
              <div class="item van-hairline--top-bottom" v-for="(item, index) in lawList" :key="item.law_id" :id="item.law_id">
                <!-- <Item
                  ref="lawitem"
                  :itemindex="index"
                  :item="item"
                  @move="handleMove"
                  @delItem="handleDelItem"
                  contentType="lawSearch"
                  @toDetail="toLawDetail"
                  :isPreview="false"
                /> -->
                <lawItem ref="lawitem" :itemindex="index" :item="item" @move="handleMove" @delItem="handleDelItem" contentType="lawSearch" @toDetail="toLawDetail" :isPreview="false" />
              </div>
              <h3 id="knowledge" v-show="knowledgeList.length" @click.stop="blurInput">知识</h3>
              <div class="item van-hairline--top-bottom" v-for="(item, index) in knowledgeList" :key="item.url" :id="item.url">
                <Item ref="knowledgeitem" :itemindex="index" :item="item" :isPreview="false" @move="handleMove" @delItem="handleDelItem" contentType="knowledgeSearch" @toDetail="toKnowledgeDetail" />
              </div>
              <h3 id="criterion" v-show="criterionList.length" @click.stop="blurInput">标准</h3>
              <div class="item van-hairline--top-bottom" v-for="(item, index) in criterionList" :key="item.nsId" :id="item.nsId">
                <Item ref="criterionitem" :itemindex="index" :item="item" :isPreview="false" @move="handleMove" @delItem="handleDelItem" contentType="criterionSearch" @toDetail="toCriterionDetail" />
              </div>
              <h3 id="agreement" v-show="agreementList.length" @click.stop="blurInput">合同</h3>
              <div class="item van-hairline--top-bottom" v-for="(item, index) in agreementList" :key="item.id" :id="item.id">
                <Item ref="agreementitem" :itemindex="index" :item="item" :isPreview="false" @move="handleMove" @delItem="handleDelItem" contentType="agreementSearch" @toDetail="toAgreementDetail" />
              </div>
              <h3 id="content" v-show="contentList.length + anaList.length" @click.stop="blurInput">正文</h3>
              <div>
                <div class="content-item" v-for="(item, index) in contentList" :id="`content-${item.id}`" :key="index">
                  <van-swipe-cell>
                    <h3 class="content-title">{{ item.title }}</h3>
                    <template #left>
                      <van-button
                        square
                        type="danger"
                        @click="
                          handleDelItem({
                            listType: 'contentList',
                            reportType: 'contentReports',
                            showConfirm: true,
                            index,
                          })
                        "
                        text="删 除"
                      />
                    </template>
                    <template #right>
                      <van-button
                        square
                        type="primary"
                        text="上移"
                        @click="
                          handleMove({
                            direction: 'up',
                            listType: 'contentList',
                            reportType: 'contentReports',
                            item,
                            index,
                          })
                        "
                      />
                      <van-button
                        square
                        type="primary"
                        text="下移"
                        @click="
                          handleMove({
                            direction: 'down',
                            listType: 'contentList',
                            reportType: 'contentReports',
                            item,
                            index,
                          })
                        "
                      />
                    </template>
                  </van-swipe-cell>
                  <div v-for="(ite, ind) in item.arr" :key="ind">
                    <h4 v-show="ite.name !== '正文' && item.isLaw">
                      {{ ite.name }}
                    </h4>
                    <p v-html="ite.text"></p>
                    <div v-for="(v, i) in ite.arr" :key="i">
                      <p v-html="v.text"></p>
                    </div>
                  </div>
                  <div v-for="(ite, idx) in item.textArr" :key="`${index}-${idx}`">
                    <p v-if="typeof item === 'string'" v-html="ite"></p>
                    <div v-else>
                      <h4>{{ ite.name }}</h4>
                      <p v-html="ite.text"></p>
                    </div>
                  </div>
                </div>
                <div class="content-item" v-for="(item, index) in anaList" :key="`ana-${index}`">
                  <div class="content-item" :id="item.id" v-if="item.type === 'law'">
                    <van-swipe-cell>
                      <h3 class="content-title">{{ item.title }}</h3>
                      <template #left>
                        <van-button
                          square
                          type="danger"
                          @click="
                            handleDelItem({
                              listType: 'anaList',
                              reportType: 'anaReports',
                              showConfirm: true,
                              index,
                            })
                          "
                          text="删 除"
                        />
                      </template>
                      <template #right>
                        <van-button
                          square
                          type="primary"
                          text="上移"
                          @click="
                            handleMove({
                              direction: 'up',
                              listType: 'anaList',
                              reportType: 'anaReports',
                              item,
                              index,
                            })
                          "
                        />
                        <van-button
                          square
                          type="primary"
                          text="下移"
                          @click="
                            handleMove({
                              direction: 'down',
                              listType: 'anaList',
                              reportType: 'anaReports',
                              item,
                              index,
                            })
                          "
                        />
                      </template>
                    </van-swipe-cell>
                    <div v-for="(ite, ind) in item.anaArr" :key="ind">
                      <h4>
                        {{ ite.name }}
                      </h4>
                      <p v-html="ite.text"></p>
                    </div>
                  </div>
                  <div class="content-item" :id="item.id" v-else-if="item.type === 'appeal'">
                    <van-swipe-cell>
                      <h3 class="content-title">
                        <span>{{ item.case_name }}</span>
                        <span>{{ item.case_id }}</span>
                      </h3>
                      <template #left>
                        <van-button
                          square
                          type="danger"
                          @click="
                            handleDelItem({
                              listType: 'anaList',
                              reportType: 'anaReports',
                              showConfirm: true,
                              index,
                            })
                          "
                          text="删 除"
                        />
                      </template>
                      <template #right>
                        <van-button
                          square
                          type="primary"
                          text="上移"
                          @click="
                            handleMove({
                              direction: 'up',
                              listType: 'anaList',
                              reportType: 'anaReports',
                              item,
                              index,
                            })
                          "
                        />
                        <van-button
                          square
                          type="primary"
                          text="下移"
                          @click="
                            handleMove({
                              direction: 'down',
                              listType: 'anaList',
                              reportType: 'anaReports',
                              item,
                              index,
                            })
                          "
                        />
                      </template>
                    </van-swipe-cell>
                    <div v-for="ite in item.data" :key="ite.title">
                      <h4>
                        {{ ite.title }}
                      </h4>
                      <p v-for="it in ite.data" :key="it.toString()">
                        {{ it }}
                      </p>
                    </div>
                  </div>
                  <div class="content-item" :id="item.id" v-else-if="item.type === 'deem'">
                    <van-swipe-cell>
                      <h3 class="content-title">
                        <span>{{ item.case_name }}</span>
                        <span>{{ item.case_id }}</span>
                      </h3>
                      <template #left>
                        <van-button
                          square
                          type="danger"
                          @click="
                            handleDelItem({
                              listType: 'anaList',
                              reportType: 'anaReports',
                              showConfirm: true,
                              index,
                            })
                          "
                          text="删 除"
                        />
                      </template>
                      <template #right>
                        <van-button
                          square
                          type="primary"
                          text="上移"
                          @click="
                            handleMove({
                              direction: 'up',
                              listType: 'anaList',
                              reportType: 'anaReports',
                              item,
                              index,
                            })
                          "
                        />
                        <van-button
                          square
                          type="primary"
                          text="下移"
                          @click="
                            handleMove({
                              direction: 'down',
                              listType: 'anaList',
                              reportType: 'anaReports',
                              item,
                              index,
                            })
                          "
                        />
                      </template>
                    </van-swipe-cell>
                    <p>{{ item.text }}</p>
                  </div>
                  <div class="content-item" :id="item.id" v-else-if="item.type === 'focus'">
                    <van-swipe-cell>
                      <p v-html="item.title" class="focus-par"></p>
                      <template #right>
                        <van-button
                          square
                          type="danger"
                          @click="
                            handleDelItem({
                              listType: 'anaList',
                              reportType: 'anaReports',
                              showConfirm: true,
                              index,
                            })
                          "
                          text="删 除"
                        />
                      </template>
                    </van-swipe-cell>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-menu" v-show="showPanel">
          <div class="menu-content">
            <div id="reportBodyMulu">
              <span class="mulu-text" @click="showPanel = false">目录</span>
              <!-- <van-checkbox
                v-model="checkedAll"
                shape="square"
                @click.native.stop="handleClickAll"
                @change="handleChangeAll"
              >
                <span @click="activeNames = []">目录</span>
                <template #icon="props">
                  <i
                    :class="{
                      'van-icon': true,
                      'van-icon-success': props.checked && allItemChecked,
                      'iconfont icon-minus': props.checked && !allItemChecked
                    }"
                  ></i>
                </template>
              </van-checkbox> -->
              <span class="all-clear" @click.stop="handleClear">全部清空</span>
            </div>
            <div class="fengmianTittleWraper" style="display: flex; justify-content: space-between; color: #666">
              <h3 id="fengmianTittle" @click="handleSwithTitle('fengmian', 'fengmianTittle')">封面</h3>
              <span class="switch-fm" @click="handleSwitchFirstPage">{{ hasFirstPage ? '隐藏封面' : '显示封面' }}</span>
            </div>
            <!-- <h3 id="reportBodyTitle">
              <span
                class="bgzw"
                @click.stop="handleSwithTitle('reportBody', 'reportBodyTitle')"
                >报告正文</span
              >
            </h3> -->
            <div id="addContentTitle"></div>
            <div class="report-mulu">
              <van-collapse v-model="activeNames">
                <van-collapse-item name="1" v-show="judgeList.length">
                  <template #title>
                    <!-- <van-checkbox
                      v-model="checkedAllJudge"
                      shape="square"
                      @click.native.stop="handleClickAll('judge')"
                      @change="handleChangeAll('judge')"
                    > -->
                    <h3 @click.stop="handleSwithTitle('judge', 'judgeTitle')" id="judgeTitle">判例</h3>
                    <!-- <template #icon="props">
                        <i
                          :class="{
                            'van-icon': true,
                            'van-icon-success': props.checked && allJudgeChecked,
                            'iconfont icon-minus':
                              props.checked && !allJudgeChecked
                          }"
                        ></i>
                      </template>
                    </van-checkbox> -->
                  </template>
                  <div v-for="(item, index1) in judgeList" :key="item.doc_id" class="mul-item" :id="`judge-${index1}`">
                    <muluItem
                      :checkedAll="checkedAll"
                      :item="item"
                      :index="index1"
                      :idName="item.doc_id"
                      :currentIdName="`judge-${index1}`"
                      type="judge"
                      @delItem="handleDelItem"
                      @switch="handleSwith"
                      @changeItemCheck="handleChange"
                    />
                    <!-- <van-checkbox
                      v-model="item.checked"
                      @change="handleChange('judgeList')"
                      shape="square"
                    ></van-checkbox>
                    &nbsp;&nbsp;
                    <span
                      v-html="item.case_name"
                      @click.stop="handleSwith(item.doc_id, `judge-${index1}`)"
                    ></span>
                    <i
                      class="iconfont icon-clear-2"
                      @click.stop="
                        handleDelItem({
                          listType: 'judgeList',
                          reportType: 'judgeReports',
                          showConfirm: false,
                          index1
                        })
                      "
                    ></i> -->
                  </div>
                </van-collapse-item>
                <van-collapse-item name="2" v-show="lawList.length">
                  <template #title>
                    <!-- <van-checkbox
                      v-model="checkedAllLaw"
                      shape="square"
                      @click.native.stop="handleClickAll('law')"
                      @change="handleChangeAll('law')"
                    > -->
                    <h3 @click.stop="handleSwithTitle('law', 'lawTitle')" id="lawTitle">法律</h3>
                    <!-- <template #icon="props">
                        <i
                          :class="{
                            'van-icon': true,
                            'van-icon-success': props.checked && allLawChecked,
                            'iconfont icon-minus': props.checked && !allLawChecked
                          }"
                        ></i>
                      </template>
                    </van-checkbox> -->
                  </template>
                  <div v-for="(item, index2) in lawList" :key="item.law_id" class="mul-item" :id="`law-${index2}`">
                    <muluItem
                      :checkedAll="checkedAll"
                      :item="item"
                      :index="index2"
                      :idName="item.law_id"
                      :currentIdName="`law-${index2}`"
                      type="law"
                      @delItem="handleDelItem"
                      @switch="handleSwith"
                      @changeItemCheck="handleChange"
                    />
                    <!-- <van-checkbox
                      v-model="item.checked"
                      @change="handleChange('lawList')"
                      shape="square"
                    ></van-checkbox>
                    &nbsp;&nbsp;
                    <span
                      v-html="item.title"
                      @click.stop="handleSwith(item.law_id, `law-${index2}`)"
                    ></span>
                    <i class="iconfont icon-clear-2"></i> -->
                  </div>
                </van-collapse-item>
                <van-collapse-item name="3" v-show="knowledgeList.length">
                  <template #title>
                    <!-- <van-checkbox
                      v-model="checkedAllKnowledge"
                      shape="square"
                      @click.native.stop="handleClickAll('knowledge')"
                      @change="handleChangeAll('knowledge')"
                    > -->
                    <h3 @click.stop="handleSwithTitle('knowledge', 'knowledgeTitle')" id="knowledgeTitle">知识</h3>
                    <!-- <template #icon="props">
                        <i
                          :class="{
                            'van-icon': true,
                            'van-icon-success':
                              props.checked && allKnowledgeChecked,
                            'iconfont icon-minus':
                              props.checked && !allKnowledgeChecked
                          }"
                        ></i>
                      </template>
                    </van-checkbox> -->
                  </template>
                  <div v-for="(item, index3) in knowledgeList" :key="item.url" class="mul-item" :id="`knowledge-${index3}`">
                    <muluItem
                      :item="item"
                      :index="index3"
                      :idName="item.url"
                      :currentIdName="`knowledge-${index3}`"
                      type="knowledge"
                      @delItem="handleDelItem"
                      @switch="handleSwith"
                      @changeItemCheck="handleChange"
                    />
                    <!-- <van-checkbox
                      v-model="item.checked"
                      @change="handleChange('knowledgeList')"
                      shape="square"
                    ></van-checkbox>
                    &nbsp;&nbsp;
                    <span
                      v-html="item.title"
                      @click.stop="handleSwith(item.url, `knowledge-${index3}`)"
                    ></span>
                    <i class="iconfont icon-clear-2"></i> -->
                  </div>
                </van-collapse-item>
                <van-collapse-item name="4" v-show="criterionList.length">
                  <template #title>
                    <!-- <van-checkbox
                      v-model="checkedAllCriterion"
                      shape="square"
                      @click.native.stop="handleClickAll('criterion')"
                      @change="handleChangeAll('criterion')"
                    > -->
                    <h3 @click.stop="handleSwithTitle('criterion', 'criterionTitle')" id="criterionTitle">标准</h3>
                    <!-- <template #icon="props">
                        <i
                          :class="{
                            'van-icon': true,
                            'van-icon-success':
                              props.checked && allCriterionChecked,
                            'iconfont icon-minus':
                              props.checked && !allCriterionChecked
                          }"
                        ></i>
                      </template>
                    </van-checkbox> -->
                  </template>
                  <div v-for="(item, index4) in criterionList" :key="item.nsId" class="mul-item" :id="`criterion-${index4}`">
                    <muluItem
                      :item="item"
                      :index="index4"
                      :idName="item.nsId"
                      :currentIdName="`criterion-${index4}`"
                      type="criterion"
                      @delItem="handleDelItem"
                      @switch="handleSwith"
                      @changeItemCheck="handleChange"
                    />
                    <!-- <van-checkbox
                      v-model="item.checked"
                      @change="handleChange('criterionList')"
                      shape="square"
                    ></van-checkbox>
                    &nbsp;&nbsp;
                    <span
                      v-html="item.stdTitle"
                      @click.stop="handleSwith(item.nsId, `criterion-${index4}`)"
                    ></span>
                    <i class="iconfont icon-clear-2"></i> -->
                  </div>
                </van-collapse-item>
                <van-collapse-item name="6" v-show="agreementList.length">
                  <template #title>
                    <h3 @click.stop="handleSwithTitle('agreement', 'agreementTitle')" id="agreementTitle">合同</h3>
                    <!-- <template #icon="props">
                        <i
                          :class="{
                            'van-icon': true,
                            'van-icon-success':
                              props.checked && allCriterionChecked,
                            'iconfont icon-minus':
                              props.checked && !allCriterionChecked
                          }"
                        ></i>
                      </template>
                    </van-checkbox> -->
                  </template>
                  <div v-for="(item, index6) in agreementList" :key="item.id" class="mul-item" :id="`agreement-${index6}`">
                    <muluItem
                      :item="item"
                      :index="index6"
                      :idName="item.id"
                      :currentIdName="`agreement-${index6}`"
                      type="agreement"
                      @delItem="handleDelItem"
                      @switch="handleSwith"
                      @changeItemCheck="handleChange"
                    />
                  </div>
                </van-collapse-item>
                <van-collapse-item name="5" v-show="contentList.length + anaList.length">
                  <template #title>
                    <!--  <van-checkbox
                      v-model="checkedAllContent"
                      shape="square"
                      @click.native.stop="handleClickAll('content')"
                      @change="handleChangeAll('content')"
                    > -->
                    <h3 @click.stop="handleSwithTitle('content', 'contentTitle')" id="contentTitle">正文</h3>
                    <!-- <template #icon="props">
                        <i
                          :class="{
                            'van-icon': true,
                            'van-icon-success':
                              props.checked && allContentChecked,
                            'iconfont icon-minus':
                              props.checked && !allContentChecked
                          }"
                        ></i>
                      </template>
                    </van-checkbox> -->
                  </template>
                  <template>
                    <div v-for="(item, index5) in contentList" :key="item.id" class="mul-item" :id="`content-${index5}`">
                      <muluItem
                        :item="item"
                        :index="index5"
                        :idName="`content-${item.id}`"
                        :currentIdName="`content-${index5}`"
                        type="content"
                        @delItem="handleDelItem"
                        @switch="handleSwith"
                        @changeItemCheck="handleChange"
                      />
                      <!-- <van-checkbox
                        v-model="item.checked"
                        @change="handleChange('contentList')"
                        shape="square"
                      ></van-checkbox>
                      &nbsp;&nbsp;
                      <span
                        v-html="item.title"
                        @click.stop="
                          handleSwith(`content-${item.id}`, `content-${index5}`)
                        "
                      ></span>
                      <i class="iconfont icon-clear-2"></i> -->
                    </div>
                  </template>
                  <template>
                    <div v-for="(item, index7) in anaList" :key="item.id" :id="`ana-${index7}`" class="mul-item">
                      <muluItem
                        :item="item"
                        :idName="`${item.id}`"
                        :currentIdName="`ana-${index7}`"
                        :index="index7"
                        type="ana"
                        @delItem="handleDelItem"
                        @switch="handleSwith"
                        @changeItemCheck="handleChange"
                      />
                      <!-- <van-checkbox
                        v-model="item.checked"
                        @change="handleChange('anaList')"
                        shape="square"
                      ></van-checkbox>
                      &nbsp;&nbsp;
                      <span
                        v-html="item.title"
                        @click.stop="handleSwith(`${item.id}`, `ana-${index6}`)"
                      ></span>
                      <i class="iconfont icon-clear-2"></i> -->
                    </div>
                  </template>
                </van-collapse-item>
              </van-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <fb-header slot="header" fixed>
        <div slot="left" class="head-left">
          <fb-button icon="back" @click="editMode = false" size="small" type="primary"></fb-button>
          <div class="collect-box">
            <span>退出编辑</span>
          </div>
        </div>
        <div slot="right">
          <div class="header-menu">
            <div class="menu-text" @click="handleClear">清空</div>
            <div class="menu-text" @click="handleShare" v-if="!$route.query.isMiniApp">分享</div>
          </div>
        </div>
      </fb-header>
      <div class="edit-report">
        <editorMode />
      </div>
    </div>
    <div class="nav" id="nav" v-if="detailshow.show">
      <lawDetail :id="detailshow.case_id" :title="detailshow.title" :catlog="detailshow.catlog" :words="detailshow.words" @changeflag="changeflag"></lawDetail>
    </div>
    <div class="nav" id="nav" v-if="detailshow.flag">
      <percentDetail :id="detailshow.case_id" :words="detailshow.words" :titleName="detailshow.name" @changeflag="changeshow"></percentDetail>
    </div>
    <div class="bottom-wrapper" v-if="!editMode && !showCaseList">
      <div class="open-panel" @click="showPanel = true" v-show="!showPanel">目录</div>
      <div class="clear-collect" @click="handleClear">清空</div>
      <div class="switch-fm" @click="handleSwitchFirstPage">
        {{ hasFirstPage ? '不显示封面' : '显示封面' }}
      </div>
    </div>
    <!-- 保存到网盘 -->
    <div class="full-screen-portal-comp" v-show="favVisible">
      <skyDrive :listid="favFolderId" :Type="'SEARCHREPORT'" @CancelCollect="cancelFav" :modulename="'分析报告'" :listname="'检索报告'" :summary="summary" @getFold="useFav"></skyDrive>
    </div>
    <!-- 关联案件列表/文件夹列表-->
    <relateCase
      @select="onSelectCase"
      :reportName="reportName"
      :tempTitle="tempTitle"
      @changeName="handleChangeName"
      :type="moveFileType"
      v-if="showCaseList"
      @cancel="onCancelSelect"
      :userId="userId"
    />
    <van-action-sheet v-model="showHistory" title="" :round="false">
      <history @open="handleOpen" v-if="showHistory" @switch="handleSwitch" :reportId="reportId" @init="handleInit" />
    </van-action-sheet>
    <!-- 单个删除 -->
    <van-dialog v-model="showDelDialog" title="" @confirm="handleDelConfirm" @cancel="handleDelCancel" show-cancel-button :overlay="true">
      <div class="van-dialog__content">
        <div class="van-dialog__message">确定删除这条报告内容吗？</div>
      </div>
    </van-dialog>
    <!-- 全部清空 -->
    <van-dialog v-model="showAllDelDialog" title="" @confirm="allDelConfirm" show-cancel-button :overlay="true">
      <div class="van-dialog__content">
        <div class="van-dialog__message">删除后，数据将无法恢复。继续删除？</div>
      </div>
    </van-dialog>
    <!-- 切换收集 -->
    <van-dialog v-model="showSwitchDialog" className="switch_dialog" title="是否保存当前收集内容？" :show-confirm-button="false" :overlay="true">
      <span class="close_btn" @click="closeDialog">x</span>
      <div class="van-hairline--top van-dialog__footer">
        <button class="van-button van-button--default van-button--large van-dialog__cancel" @click="cancelSwitch">
          <div class="van-button__content">
            <span class="van-button__text">否</span>
          </div>
        </button>
        <button @click="confirmSwitch" class="van-button van-button--default van-button--large van-dialog__confirm van-hairline--left">
          <div class="van-button__content">
            <span class="van-button__text">是</span>
          </div>
        </button>
      </div>
    </van-dialog>
    <!-- loading框 -->
    <van-dialog class="loading-dialog" v-model="showfullLoading" :show-confirm-button="false" :overlay="true">
      <van-loading />
    </van-dialog>
    <!-- 提示下载 -->
    <van-dialog class="download-dialog" v-model="showDownload" title="准备下载，请确认" :show-confirm-button="false" :overlay="true">
      <div @click="cancel" class="cancel">x</div>
      <div class="van-dialog__content">
        <div class="van-dialog__message">
          <van-button type="info" size="small" :loading="showLoadOne" @click="handleDownLoadReport(false)">只下载原报告</van-button>
          <van-button type="info" size="small" :loading="showLoadTwo" @click="handleDownLoadReport(true)">下载原报告加判例全文</van-button>
        </div>
      </div>
    </van-dialog>
    <!-- 操作提示 -->
    <transition name="fade">
      <div :class="{ notify: true, [actionNotify.type]: true }" v-show="actionNotifyVisible">
        {{ actionNotify.msg }}
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'
// import goBackMixin from '@mixins/goBack'
import BScroll from 'better-scroll'
import judgementItem from './components/judgementItem'
import lawItem from './components/lawItem'
import muluItem from './components/muluItem'
import Item from './components/item'
import skyDrive from '@/pages/skyDrive/index'
import percentDetail from '@/pages/precedentDetail'
import lawDetail from '@/pages/Search/pages/lawDetail'
import relateCase from './components/relateCase'
import history from './components/history'
import editorMode from './components/editorMode'
export default {
  name: 'searchReport',
  props: ['from'],
  // mixins: [goBackMixin],
  components: {
    judgementItem,
    lawItem,
    muluItem,
    Item,
    skyDrive,
    percentDetail,
    lawDetail,
    relateCase,
    history,
    editorMode,
  },
  data() {
    return {
      FileExt: [], // 获取文件后缀
      editMode: false, // 是否是编辑模式
      wordReport: '', // 手动输入的文本报告
      showMenuList: false, // 操作菜单
      moveFileType: 'case',
      showCaseList: false, // 关联案件/另存文件面板
      showHistory: false, // 历史记录面板
      showDownload: false, // 下载面板
      showLoadOne: false,
      showLoadTwo: false,
      // relateCaseName: '',
      searchCollectionId: null,
      showAddText: true,
      showAreaText: false,
      activeNames: ['1', '2', '3', '4', '5', '6'],
      showPanel: false,
      judgeList: [],
      lawList: [],
      criterionList: [],
      knowledgeList: [],
      agreementList: [],
      contentList: [],
      anaList: [],
      lawWords: [],
      judgeWords: [],
      hasFirstPage: false,
      lawFirmName: '',
      editLawFirmName: false,
      editUserName: false,
      userName: '',
      avatarUrl: '',
      userId: '',
      dayTime: '',
      reportName: '',
      actionNotify: {
        msg: '收藏成功',
        type: 'success',
      },
      actionNotifyVisible: false,
      notifyel: null,
      detailshow: {
        show: false,
        case_id: null,
        title: null,
        flag: false,
        showIpt: false,
        catlog: null,
        name: null,
        section: null,
      },
      checkedAll: false, // 全部选中
      allItemChecked: false, // 全部子项选中
      /* checkedAllJudge: false,
      checkedAllLaw: false,
      checkedAllCriterion: false,
      checkedAllKnowledge: false,
      checkedAllContent: false,
      checkedAllAna: false,
      allJudgeChecked: false,
      allLawChecked: false,
      allKnowledgeChecked: false,
      allCriterionChecked: false,
      allContentChecked: false,
      allAnaChecked: false, */
      tempDelItem: null,
      showfullLoading: false,
      showDelDialog: false,
      showAllDelDialog: false,
      showSwitchDialog: false,
      // 生成报告成功后 接口返回的数据
      savedReportData: null,
      // reportId: null, // 报告id
      // 收藏组件开关
      favVisible: false,
      // 收藏的文件夹id 用于查看报告
      favFolderId: 0,
      reportTimer: null,
      showJudgeMulBtns: false,
      showLawMulBtns: false,
      clickTimes: 0,
      showShare: false,
    }
  },
  computed: {
    ...mapGetters('searchReport', ['reportCount']),
    ...mapState('user', ['userInfo', 'organization']),
    ...mapState('ana', ['text', 'summary', 'anaTwoKeyDown']),
    ...mapState('searchReport', [
      'judgeReports',
      'lawReports',
      'contentReports',
      'textReport',
      'anaReports',
      'knowledgeReports',
      'criterionReports',
      'agreementReports',
      // 'tempReport',
      'ordinaryReport',
    ]),
    // 报告id
    reportId() {
      if (this.ordinaryReport) {
        return this.ordinaryReport.id
      } else {
        return ''
      }
    },
    // 报告关联案件 label
    relateCaseName() {
      if (this.ordinaryReport) {
        return this.ordinaryReport.label || '暂无关联案件'
      } else {
        return '暂无关联案件'
      }
    },
    // 当前页面content
    contentToSave() {
      const currentContent = {
        judgeReports: [...this.judgeList],
        lawReports: [...this.lawList],
        criterionReports: [...this.criterionList],
        knowledgeReports: [...this.knowledgeList],
        agreementReports: [...this.agreementList],
        contentReports: [...this.contentList],
        anaReports: [...this.anaList],
        textReport: this.wordReport,
        userName: this.userName,
        lawFirmName: this.lawFirmName,
        dayTime: this.dayTime,
        hasFirstPage: this.hasFirstPage,
        // avatarUrl: this.avatarUrl,
      }
      return currentContent
    },
    tempTitle() {
      const today = new Date()
      const dayTime =
        today.getFullYear() +
        '.' +
        (today.getMonth() + 1) +
        '.' +
        today.getDate() +
        ' ' +
        (today.getHours() > 9 ? today.getHours() : '0' + today.getHours()) +
        ':' +
        (today.getMinutes() > 9 ? today.getMinutes() : '0' + today.getMinutes())
      const title = `${dayTime}${this.userInfo ? this.userInfo.nickName + '收集' : '某某收集'}${this.judgeList.length > 0 ? this.judgeList.length + '判' : ''}${
        this.lawList.length > 0 ? this.lawList.length + '法' : ''
      }${this.knowledgeList.length > 0 ? this.knowledgeList.length + '知' : ''}${this.criterionList.length > 0 ? this.criterionList.length + '标' : ''}${
        this.contentList.length + this.anaList.length > 0 ? this.contentList.length + this.anaList.length + '文' : ''
      }`
      return title
    },
    // userAgent
    allCount() {
      const allList = [...this.judgeList, ...this.lawList, ...this.knowledgeList, ...this.criterionList, ...this.contentList, ...this.anaList]
      return allList.length
    },
    allCheckedCount() {
      const allList = [...this.judgeList, ...this.lawList, ...this.knowledgeList, ...this.criterionList, ...this.contentList, ...this.anaList]
      return allList.filter((item) => item.checked).length
    },
    ua() {
      return navigator.userAgent
    },
    isIOS() {
      return navigator.userAgent === this.$ua.i
    },
  },
  async created() {
    this.userId = this.userInfo.id
    if (this.$route.name === 'searchReport' && this.userId) {
      this.showfullLoading = true
      try {
        this.getStampReport({
          userId: this.userId,
          callback: () => {
            this.asyncVuexReport()
            this.showfullLoading = false
          },
        })
      } catch (err) {
        this.showfullLoading = false
      }
    } else {
      this.asyncVuexReport()
    }
  },
  mounted() {
    if (this.userInfo && this.userInfo.account === '13012341234') {
      this.showShare = true
    } else {
      this.showShare = false
    }
    const list = document.getElementsByClassName('vue-portal-target')
    for (var i = 0; i < list.length; i++) {
      list[i].style.display = 'none'
    }
    setTimeout(() => {
      const list = document.getElementsByClassName('vue-portal-target')
      for (var i = 0; i < list.length; i++) {
        list[i].style.display = 'none'
      }
      const el = document.getElementsByClassName('menuOfDetailInCaseInAna')[0]
      if (el) {
        el.style.display = 'none'
      }
      this.filterReportContentEM(this.judgeList)
      this.filterReportContentEM(this.lawList)
    }, 200)
    // console.log(this.contentList, 'contentList contentList')
    setTimeout(() => {
      this.scroll = new BScroll(this.$refs.searchReport, {
        click: true,
        bounce: false,
      })
    }, 20)
  },
  methods: {
    ...mapActions('searchReport', ['getStampReport', 'setStampReport']),
    ...mapMutations('searchReport', [
      'SET_NEW_ORDINARY_REPORT',
      'SET_KNOWLEDGE_REPORT',
      'SET_JUDGE_REPORT',
      'SET_LAW_REPORT',
      'SET_TEXT_REPORT',
      'SET_ANA_REPORT',
      'SET_CONTENT_REPORT',
      'SET_CRITERION_REPORT',
    ]),

    cancel() {
      this.showDownload = false
    },
    /**
     * 深拷贝
     * @param {Object} fromObj
     * @param {Object} toObj
     */
    deepCopy(fromObj, toObj) {
      for (const key in fromObj) {
        const value = fromObj[key]
        if (value instanceof Object) {
          // 引用数据类型
          const tempObj = new value.constructor()
          this.deepCopy(value, tempObj)
          toObj[key] = tempObj
        } else {
          // 简单数据类型
          toObj[key] = value
        }
      }
    },
    // store中数据同步到页面
    asyncVuexReport() {
      const today = new Date()
      const dayTime =
        today.getFullYear() +
        '.' +
        (today.getMonth() + 1) +
        '.' +
        today.getDate() +
        ' ' +
        (today.getHours() > 9 ? today.getHours() : '0' + today.getHours()) +
        ':' +
        (today.getMinutes() > 9 ? today.getMinutes() : '0' + today.getMinutes())
      let lawFirmName = ''
      if (this.userInfo) {
        const { searchCollectionId, organization, lawyer } = this.userInfo
        this.searchCollectionId = searchCollectionId || ''
        if (organization && organization.name) {
          lawFirmName = organization.name
        } else if (lawyer && lawyer.lawFirmName) {
          lawFirmName = lawyer.lawFirmName
        } else {
          lawFirmName = ''
        }
      }

      this.judgeList = this.judgeReports || []
      this.lawList = this.lawReports || []
      this.contentList = this.contentReports || []
      this.criterionList = this.criterionReports || []
      this.agreementList = this.agreementReports || []
      this.knowledgeList = this.knowledgeReports || []
      this.anaList = this.anaReports || []
      this.wordReport = this.textReport || ''
      // this.judgeWords = this.judgeWords || []
      // this.lawWords = this.lawWords || []
      if (this.wordReport) {
        this.showAddText = false
        this.showAreaText = true
      }
      if (!this.ordinaryReport) return
      const content = JSON.parse(this.ordinaryReport.content)
      this.lawFirmName = content.lawFirmName || lawFirmName || ''
      this.userName = content.userName || this.userInfo.nickName || ''
      this.dayTime = content.dayTime || dayTime
      // this.avatarUrl = content.avatarUrl || ''
      this.hasFirstPage = !!content.hasFirstPage
      if (this.judgeList && this.judgeList.length) {
        this.judgeList.forEach((item, index) => {
          this.deepCopy(this.judgeList[index].content, item.content)
          if (item.content && item.content.length) {
            if (item.content[item.content.length - 1].name === '结果命中') {
              item.content.pop()
            }
          }
        })
      }
      if (this.lawList && this.lawList.length) {
        this.lawList.forEach((item, index) => {
          this.deepCopy(this.lawList[index].content, item.content)
        })
      }
    },
    // 同步数据到vuex中和页面中
    init(data) {
      const reportData = JSON.parse(data.content || '{}')
      this.judgeList = reportData.judgeReports || []
      this.lawList = reportData.lawReports || []
      this.contentList = reportData.contentReports || []
      this.criterionList = reportData.criterionReports || []
      this.knowledgeList = reportData.knowledgeReports || []
      this.anaList = reportData.anaReports || []
      this.wordReport = reportData.textReport || ''
      // this.judgeWords = reportData.judgeWords || []
      // this.lawWords = reportData.lawWords || []
      this.userName = reportData.userName || ''
      this.lawFirmName = reportData.lawFirmName || ''
      this.dayTime = reportData.dayTime || ''
      this.avatarUrl = reportData.avatarUrl || ''
      this.hasFirstPage = !!reportData.hasFirstPage
      this.SET_NEW_ORDINARY_REPORT(data)
      this.SET_JUDGE_REPORT(this.judgeList)
      this.SET_LAW_REPORT(this.lawList)
      this.SET_KNOWLEDGE_REPORT(this.knowledgeList)
      this.SET_CRITERION_REPORT(this.criterionList)
      this.SET_CONTENT_REPORT(this.contentList)
      this.SET_ANA_REPORT(this.anaList)
      this.SET_TEXT_REPORT(this.wordReport)
      // 封面或添加内容选中
      setTimeout(() => {
        if (this.hasFirstPage) {
          if (document.getElementById('fengmianTittle')) {
            document.getElementById('fengmianTittle').className = 'active-title'
          }
        } else {
          if (document.getElementById('addContentTitle')) {
            this.handleSwithTitle('addContent', 'addContentTitle')
          }
        }
      }, 200)
    },
    // 有reportId获取检索报告内容
    async getDataByReportId(id) {
      this.showfullLoading = true
      const { data } = await this.$axios.get(`${this.$base}/management/user/${this.userId}/collection/${id}`)
      if (data.code === 200 && data.data) {
        this.init(data.data)
      }
      this.showfullLoading = false
    },
    // 修改检索报告数据
    setDataByReportId(timeout = 0) {
      this.setStampReport({
        userId: this.userId,
        type: 'allReports',
        reportData: { ...this.contentToSave },
        timeout,
        callback: () => {
          this.asyncVuexReport()
          this.showfullLoading = false
        },
      })
    },
    // 律所/用户名获焦事件
    focusTextInput() {
      this.showAreaText = false
      setTimeout(() => {
        this.$refs.textInput.focus()
      }, 200)
    },
    // 律所/用户名失焦事件-修改用户名律所名称
    blurInput() {
      setTimeout(() => {
        this.showAreaText = true
        this.editUserName = false
        this.editLawFirmName = false
        if (this.reportId) {
          this.setDataByReportId(6000)
        }
      }, 200)
    },
    // 修改律所名称
    clickLawFirmName() {
      this.editLawFirmName = true
      setTimeout(() => {
        this.$refs.lawFirmNameInput.focus()
      }, 200)
    },
    // 修改用户名
    clickUserName() {
      this.editUserName = true
      setTimeout(() => {
        this.$refs.userNameInput.focus()
      }, 200)
    },
    // 返回到上一个界面
    handleGoBack() {
      this.$emit('close')
      // this.goBack()
    },
    // 原生返回
    goBack() {
      if (this.$router.length <= 1) {
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    backToNative() {
      const iOSUA = 'fb-iOS-Webview'
      const AndroidUA = 'fb-Android-Webview'
      const flutterUA = 'fb-flutter-Webview'
      const ua = navigator.userAgent
      if (ua === AndroidUA) {
        try {
          console.log('AndroidUA**********')
          // eslint-disable-next-line
          AND2JS.back()
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === iOSUA) {
        try {
          console.log('iOSUA**********')
          // eslint-disable-next-line
          window.webkit.messageHandlers.back.postMessage({})
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === flutterUA) {
        try {
          // eslint-disable-next-line
          window.back.postMessage('{}')
        } catch (err) {
          this.$notify('没有可以返回的页面')
          console.log('back.postMessage', err)
        }
      } else {
        console.log('router**********')
        this.$router.back()
      }
    },
    clickJudge() {
      this.blurInput()
      this.showJudgeMulBtns = !this.showJudgeMulBtns
    },
    clickLaw() {
      this.blurInput()
      this.showLawMulBtns = !this.showLawMulBtns
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    // 批量下载
    handleMulDownload() {
      this.clickTimes++
      if (!this.userId) {
        this.$notify('请先登录')
      }
      const selectedListId = this.judgeReports.length ? this.judgeReports.map((item) => item.doc_id) : []
      const date = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      console.log('时间戳:', date)
      const fileName = `${date}_${this.clickTimes}收集${selectedListId.length}篇判例`
      console.log(fileName)
      const ids = selectedListId.map((item, index) => {
        return {
          id: item,
          num: index + 1,
        }
      })
      // console.log(ids)
      this.$axios
        .post(`${this.$base}/document/user/${this.userId}/legal/precedent/file`, {
          fileName,
          ids,
        })
        .then((res) => {
          console.log(res, '测试')
          const { code, data } = res.data
          if (Number(code) === 200 && data) {
            const { path } = data
            this.fbDownLoad(path, fileName)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 批量分享
    handleMulShare(list) {
      let id = ''
      let base = window.origin
      if (list === 'judgeReports') {
        id = 'doc_id'
        base += '/transform/searchKeepAlive/precedentDetail?docid='
      } else if (list === 'lawReports') {
        id = 'law_id'
        base += '/search/law/'
      }
      const promiseArr = []
      this[list].forEach((v) => {
        const url = base + v[id]
        const p = longUrl2ShortUrl(url)
        promiseArr.push(p)
      })
      let text = ''
      Promise.all(promiseArr)
        .then((res) => {
          console.log(res, 'res')
          res.forEach((v, i) => {
            text += i + 1 + '、' + v.data + ' '
          })
          console.log(text, 'shareJudge---分享')
          if (text) {
            this.copyText(text)
            this.actionNotify = {
              msg: '已复制到粘贴板上，请粘贴给需要的人',
              type: 'success',
            }
            this.showActionSuccessNotify()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 退出判例法律详情页预览
    changeflag() {
      this.detailshow.show = false
    },
    changeshow() {
      this.detailshow.flag = false
    },
    // 下载app
    downloadApp() {
      window.open('https://sj.qq.com/myapp/detail.htm?apkName=com.farbun.fb')
    },
    // 点击清空
    handleClear() {
      this.showAllDelDialog = true
    },
    // 确定清空
    allDelConfirm() {
      if (this.reportId) {
        this.showfullLoading = true
        this.judgeList = []
        this.lawList = []
        this.knowledgeList = []
        this.agreementList = []
        this.criterionList = []
        this.contentList = []
        this.anaList = []
        this.wordReport = ''
        this.hasFirstPage = false
        this.showfullLoading = true
        this.setDataByReportId()
      }
    },
    // 单个删除确定
    handleDelConfirm() {
      if (this.reportId) {
        this[this.tempDelItem.listType].splice(this.tempDelItem.index, 1)
        this.setDataByReportId(6000)
      }
    },
    // 单个删除取消
    handleDelCancel() {
      this.tempDelItem = null
      this.showDelDialog = false
    },
    // 删除一条报告
    handleDelItem(payload) {
      console.log(payload, '删除一条报告')
      if (payload.showConfirm) {
        this.tempDelItem = payload
        this.showDelDialog = true
      } else {
        if (this.reportId) {
          this[payload.listType].splice(payload.index, 1)
          this.setDataByReportId(6000)
        }
      }
    },
    // 处理移动一条报告
    handleMove(payload) {
      const { direction, index, listType, item } = payload
      const list = JSON.parse(JSON.stringify(this[listType]))
      this[listType] = []
      if (direction === 'up' && index > 0) {
        list[index] = JSON.parse(JSON.stringify(list[index - 1]))
        list[index - 1] = JSON.parse(JSON.stringify(item))
      } else if (direction === 'down' && index < list.length - 1) {
        list[index] = JSON.parse(JSON.stringify(list[index + 1]))
        list[index + 1] = JSON.parse(JSON.stringify(item))
      } else {
        console.log('无法移动了')
        this.$notify('无法移动了')
        this[listType] = [...list]
        return
      }
      this[listType] = [...list]
      if (this.reportId) {
        this.setDataByReportId(6000)
      }
    },
    // 跳转判例详情页面
    toJudgeDetail(payload, source) {
      console.log(payload, source, 'toDetail')
      // TODO 完善打开详情页代码
      if (typeof payload === 'object') {
        this.detailshow.title = payload.catlog
        this.detailshow.case_id = payload.docid
        this.detailshow.flag = true
      } else if (source === undefined) {
        this.detailshow.case_id = payload
        this.detailshow.show = true
      }
    },
    // 跳转法律详情页面
    toLawDetail(item) {
      const { law_id: id, hit_datas: hit } = item
      this.detailshow.case_id = id
      // 通过设置title为 第n条 以实现打开后跳转
      if (hit && hit.catlog && hit.catlog.length) {
        this.detailshow.title = hit.catlog[0]
      }
      this.detailshow.show = true
    },
    // 知识跳转页面
    toKnowledgeDetail(payload) {
      // console.log('知识跳转页面', payload.url, payload.source)
      if (payload.source !== undefined) {
        this.postToNative(payload.url, payload.source)
      }
    },
    // 跳转到标准详情
    toCriterionDetail(url) {
      // console.log('跳转到标准详情', url)
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.showNews(url)
      } else if (ua === this.$ua.i) {
        return this.$notify('苹果端不支持查看')
        // window.webkit.messageHandlers.showNews.postMessage(url)
      }
    },
    toAgreementDetail() {
      console.log('暂不支持查看')
    },
    // 调用原生端跳转页面方法
    postToNative(url, source) {
      setTimeout(() => {
        const ua = this.ua
        if (ua === this.$ua.a) {
          // eslint-disable-next-line no-undef
          AND2JS.showNews(url)
        } else if (ua === this.$ua.i) {
          window.webkit.messageHandlers.showNews.postMessage(url)
        }
      })
    },
    // 点击全选和取消
    handleClickAll(type) {
      const UpperType = type.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
      console.log(UpperType, type, 'UpperType--------type')
      if (type) {
        const checkedAllType = 'checkedAll' + UpperType
        const allTypeChecked = 'all' + UpperType + 'Checked'
        const typeList = type + 'List'
        this[checkedAllType] = !this[checkedAllType]
        this[allTypeChecked] = this[checkedAllType]
        this[typeList].forEach((item, i) => {
          this.$set(this[typeList][i], 'checked', this[checkedAllType])
        })
        if (type === 'content') {
          console.log('content')
          this.anaList.forEach((item, i) => {
            this.$set(this.anaList[i], 'checked', this[checkedAllType])
          })
        }
      } else {
        this.checkedAll = !this.checkedAll
        this.allItemChecked = this.checkedAll
        this.judgeList.forEach((item, i) => {
          this.$set(this.judgeList[i], 'checked', this.checkedAll)
        })
        this.lawList.forEach((item, i) => {
          this.$set(this.lawList[i], 'checked', this.checkedAll)
        })
        this.knowledgeList.forEach((item, i) => {
          this.$set(this.knowledgeList[i], 'checked', this.checkedAll)
        })
        this.criterionList.forEach((item, i) => {
          this.$set(this.criterionList[i], 'checked', this.checkedAll)
        })
        this.contentList.forEach((item, i) => {
          this.$set(this.contentList[i], 'checked', this.checkedAll)
        })
        this.anaList.forEach((item, i) => {
          this.$set(this.anaList[i], 'checked', this.checkedAll)
        })
      }

      console.log('点击了全选和取消 ++++ ')
    },
    // 触发选择
    handleChangeAll() {
      // console.log('触发了勾选和取消全选 ---- ')
    },
    // 单选
    handleChange(type) {
      if (type && type === 'ana') {
        type = 'content'
      }
      const UpperType = type.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
      if (type) {
        const checkedAllType = 'checkedAll' + UpperType
        const allTypeChecked = 'all' + UpperType + 'Checked'
        const typeList = type + 'List'
        let allTypeCount = 0
        let allTypeCheckedCount = 0
        if (type === 'content') {
          allTypeCount = [...this.contentList, ...this.anaList].length
          allTypeCheckedCount = [...this.contentList, ...this.anaList].filter((item) => item.checked).length
        } else {
          allTypeCount = this[typeList].length
          allTypeCheckedCount = this[typeList].filter((item) => item.checked).length
        }
        if (allTypeCount === allTypeCheckedCount) {
          this[allTypeChecked] = true
        } else {
          this[allTypeChecked] = false
        }
        if (allTypeCheckedCount > 0) {
          this[checkedAllType] = true
        } else {
          this[checkedAllType] = false
        }
      }
      if (this.allCount === this.allCheckedCount) {
        this.allItemChecked = true
      } else {
        this.allItemChecked = false
      }
      if (this.allCheckedCount > 0) {
        this.checkedAll = true
      } else {
        this.checkedAll = false
      }
    },
    // 点击目录标题
    handleSwithTitle(idName, currentIdName) {
      if (idName === 'fengmian' && currentIdName === 'fengmianTittle' && !this.hasFirstPage) {
        return
      }
      this.handleSwith({ idName, currentIdName })
    },
    // 点击目录切换到指定的位置
    handleSwith({ idName, currentIdName }) {
      const lastActiveEle = document.getElementsByClassName('active-title')[0]
      const currentEle = document.getElementById(currentIdName)
      if (lastActiveEle) {
        this.removeClassName(lastActiveEle, 'active-title')
      }
      if (currentEle) {
        this.addClassName(currentEle, 'active-title')
      }
      this.scroll.scrollToElement(document.getElementById(idName), 200, 0, 0)
    },
    removeClassName(node, _className) {
      var obj = node
      if (_className && obj && obj.classList && obj.classList.remove) {
        // 浏览器特性判断是否支持classList及对应的remove方法
        obj.classList.remove(_className)
      } else {
        obj.className = obj.className.replace(new RegExp('\\b' + _className + '\\b'), '')
      }
    },
    addClassName(node, className) {
      var cn = node.className
      if (className && node && node.classList && node.classList.add) {
        // 浏览器特性判断是否支持classList及对应的add方法
        node.classList.add(className)
      } else {
        if ((' ' + cn + ' ').indexOf(' ' + className + ' ') === -1) {
          node.className = cn + ' ' + className
        }
      }
    },
    // 保存
    handleSave() {
      this.favVisible = true
    },
    // 另存为
    handleSaveElse() {
      this.showMenuList = false
      this.moveFileType = 'folder'
      this.showCaseList = true
    },
    // 取消收藏
    cancelFav() {
      this.favVisible = false
      this.showMenuList = false
      console.log('点击取消', this.favVisible)
    },
    // 显示操作提示
    showActionSuccessNotify() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.actionNotifyVisible = true
      this.timer = setTimeout(() => {
        this.actionNotifyVisible = false
      }, 1000)
    },
    // 使用文件夹id生成报告（将报告保存到该文件夹下)
    async createReportWithFolder(folder) {
      const url = `${this.$base}/management/user/${this.userId}/collection`
      const contentToSave = {
        judgeReports: this.judgeList,
        lawReports: this.lawList,
        criterionReports: this.criterionList,
        knowledgeReports: this.knowledgeList,
        contentReports: this.contentList,
        anaReports: this.anaList,
        textReport: this.wordReport.trim(),
        // judgeWords: this.judgeWords,
        // lawWords: this.lawWords,
        userName: this.userName,
        lawFirmName: this.lawFirmName,
        dayTime: this.dayTime,
        hasFirstPage: this.hasFirstPage,
        avatarUrl: this.avatarUrl,
      }
      const d = {
        content: JSON.stringify(contentToSave),
        level: folder.level,
        name: this.reportName && this.reportName !== '临时检索报告' ? this.reportName : this.tempTitle,
        size: 0,
        parentId: folder.parentId,
        type: 'SEARCHREPORT',
        createTime: new Date().getTime(),
      }
      if (this.relateCaseName && this.relateCaseName !== '暂无关联案件') {
        d.label = this.relateCaseName
      }
      try {
        const { data } = await this.$axios.post(url, d)
        if (data.code === 200) {
          return data.dataasync
        } else {
          return null
        }
      } catch (err) {
        this.$notify('生成报告失败')
        console.log(err)
      }
    },
    // 获取 创建分析报告需要的id （通过收藏实现）
    async getReportSaveId() {
      console.log('transform getReportSaveId')
      const { data } = await this.$axios.get(`${this.$base}/management/users/${this.userId}`)
      if (data.code === 200 && data.data) {
        return data.data.reportscollectionId || data.data.temporaryCollectionId
      } else {
        return null
      }
    },
    // 收藏组件点击 #收藏至此# 取得上级文件夹id
    async useFav(parent) {
      if (!parent) {
        return this.$notify('选择的文件夹数据存在问题')
      }
      this.favFolderId = parent.parentId
      this.favVisible = false
      this.showMenuList = false
      const data = this.createReportWithFolder(parent)
      if (data) {
        this.savedReportData = data
        this.reportId = data.id
        this.actionNotify = {
          msg: '收藏成功',
          type: 'success',
        }
        this.showActionSuccessNotify()
      }
    },
    // 切换封面
    handleSwitchFirstPage() {
      console.log('切换封面', this.hasFirstPage)
      if (this.hasFirstPage) {
        this.hasFirstPage = false
        const fengmianEle = document.getElementById('fengmianTittle')
        // const lastActiveEle = document.getElementsByClassName('active-title')[0]
        if (fengmianEle.className === 'active-title') {
          this.removeClassName(fengmianEle, 'active-title')
          setTimeout(() => {
            this.handleSwithTitle('addContent', 'addContentTitle')
          })
        }
      } else {
        this.handleAddFirstPage()
      }
    },
    // 增加封面
    handleAddFirstPage() {
      const lastActiveEle = document.getElementsByClassName('active-title')[0]
      if (lastActiveEle) {
        lastActiveEle.className = ''
      }
      this.hasFirstPage = true
      document.getElementById('fengmianTittle').className = 'active-title'
    },
    // 改名
    async handleChangeName(name) {
      if (!this.reportId) return
      const d = {
        name,
        type: 'SEARCHREPORT',
        id: this.reportId,
      }
      try {
        const { data } = await this.$axios.post(`${this.$base}/management/collection/updateFileInfos`, d)
        // this.reportName = name
        this.SET_NEW_ORDINARY_REPORT(data.data)
      } catch (error) {
        console.log(error)
      }
    },
    toShare() {
      this.$router.push('/user/myShare')
    },
    // 分享 没有点保存直接分享默认先保存在根文件夹下
    async handleShare() {
      let name = ''
      let reportId = ''
      if (this.reportId) {
        const folder = {
          level: 1,
          parentId: this.searchCollectionId,
        }
        const data = await this.createReportWithFolder(folder)
        reportId = data.id
        // 更新检索报告
        name = this.reportName && this.reportName !== '临时检索报告' ? this.reportName : this.tempTitle
        console.log(name, this.reportId, reportId, '+++++++++++++')
      }
      if (name && reportId) {
        const ua = this.ua
        let shareUrl = `https://tool.farbun.com/transform/searchReport/${reportId}`
        if (this.$base === 'https://api.dev.farbun.com') {
          shareUrl = `https://dev.tool.farbun.com/transform/searchReport/${reportId}`
        } else if (this.$base.includes('dev')) {
          shareUrl = `https://dev.tool.farbun.com/transform/searchReport/${reportId}`
        } else {
          shareUrl = `https://tool.farbun.com/transform/searchReport/${reportId}`
        }
        console.log(name, '===name')
        if (ua === 'fb-flutter-Webview') {
          // flutter分享方法
          window.shareCaseFile.postMessage(
            JSON.stringify({
              filename: name,
              description: '律呗法律大数据',
              url: window.encodeURI(shareUrl),
            })
          )
        } else if (ua === this.$ua.a) {
          // eslint-disable-next-line no-undef
          AND2JS.shareCaseFile(name, window.encodeURI(shareUrl))
        } else if (ua === this.$ua.i) {
          window.webkit.messageHandlers.shareCaseFile.postMessage(
            JSON.stringify({
              filename: name,
              url: window.encodeURI(shareUrl),
            })
          )
        } else {
          this.copyText(shareUrl)
          this.$notify('网页端暂不支持分享,已复制链接请粘贴给需要分享的人')
        }
      } else {
        this.$notify('未能获取到需要的数据')
      }
    },
    // 复制到粘贴板上
    copyText(url) {
      let text = url
      text = text.replace(/<[^>]*>/g, '')
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
    },
    // 关联案件
    handleRelateCase() {
      this.moveFileType = 'case'
      this.showCaseList = true
    },
    // 取消选择
    onCancelSelect() {
      this.showCaseList = false
    },
    // 选中列表中文件/案件
    async onSelectCase(item) {
      const successMsg = `${this.moveFileType === 'case' ? '关联案件成功' : '保存成功'}`
      const errorMsg = `${this.moveFileType === 'case' ? '关联案件失败' : '保存失败'}`
      this.showCaseList = false
      if (this.moveFileType === 'folder') {
        // 点击另存为或者是没有reportId的临时检索报告页面
        const folder = {
          level: item.level + 1,
          parentId: item.id,
        }
        const data = await this.createReportWithFolder(folder)
        if (data && data.data) {
          this.showfullLoading = true
          this.setDataByReportId()
          this.actionNotify = {
            msg: successMsg,
            type: 'success',
          }
        } else {
          this.actionNotify = {
            msg: errorMsg,
            type: 'error',
          }
        }
        this.showActionSuccessNotify()
      } else {
        // 有reportId且点击关联案件
        this.$axios
          .put(`${this.$base}/management/user/${this.userId}/collection`, [
            {
              parentId: item.id,
              id: this.reportId,
              label: item.name || '',
            },
          ])
          .then((res) => {
            this.SET_NEW_ORDINARY_REPORT(res.data.data[0])
            this.actionNotify = {
              msg: successMsg,
              type: 'success',
            }
            this.showActionSuccessNotify()
          })
          .catch((err) => {
            this.actionNotify = {
              msg: errorMsg,
              type: 'error',
            }
            this.showActionSuccessNotify()
            console.log(err)
          })
      }
    },
    handleInit() {
      this.showfullLoading = true
      this.getStampReport({
        userId: this.userId,
        callback: () => {
          this.asyncVuexReport()
          this.showfullLoading = false
        },
      })
    },
    handleOpen(item) {
      // console.log(item, '--->>>handleOpen打开功能')
      const reportId = item.id
      this.showHistory = false
      this.getDataByReportId(reportId)
    },
    // 切换新收集
    handleSwitch() {
      this.showSwitchDialog = true
    },
    closeDialog() {
      this.showSwitchDialog = false
    },
    confirmSwitch() {
      this.showSwitchDialog = false
      this.showfullLoading = true
      this.setStampReport({
        userId: this.userId,
        type: 'allReports',
        title: this.reportName || this.tempTitle,
        reportData: this.contentToSave,
        timeout: 0,
        callback: () => {
          this.addEmptyReport()
        },
      })
    },
    async cancelSwitch() {
      this.showSwitchDialog = false
      this.showfullLoading = true
      await this.deleteReport()
    },
    // 新增一条content为空没有关联案件临时文件名的数据
    addEmptyReport() {
      const userName = this.user ? this.user.nickName : ''
      const today = new Date()
      const dayTime = today.getFullYear() + '.' + (today.getMonth() + 1) + '.' + today.getDate()
      let lawFirmName = ''
      if (this.userInfo) {
        const { organization, lawyer } = this.userInfo
        if (organization && organization.name) {
          lawFirmName = organization.name
        } else if (lawyer && lawyer.lawFirmName) {
          lawFirmName = lawyer.lawFirmName
        } else {
          lawFirmName = ''
        }
      }
      const currentContent = {
        judgeReports: [],
        lawReports: [],
        contentReports: [],
        criterionReports: [],
        knowledgeReports: [],
        agreementReports: [],
        anaReports: [],
        userName,
        lawFirmName,
        dayTime,
        hasFirstPage: false,
      }
      // 设置默认名称
      this.setStampReport({
        userId: this.userId,
        type: 'allReports',
        reportData: currentContent,
        title: '临时检索报告',
        timeout: 0,
        isAdd: true,
        callback: async () => {
          this.asyncVuexReport()
          this.showfullLoading = false
        },
      })
    },
    // 删除一条报告
    async deleteReport() {
      const arr = [{ id: this.reportId }]
      const res = await this.$axios({
        url: `${this.$base}/management/user/${this.userId}/collection`,
        method: 'delete',
        data: arr,
        header: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      })
      if (res.data.code === 200) {
        this.addEmptyReport()
        return res.data.data
      }
    },
    // 下载
    clickDownload() {
      this.showMenuList = false
      this.showDownload = true
    },
    // 过滤报告中的em标签
    filterReportContentEM(reportList) {
      const list = [...reportList].map((item) => {
        const obj = item
        if (item.content) {
          const mzObj = item.content.find((itemr) => itemr.name === '结果命中')
          obj.content = item.content.filter((itemr) => itemr.name !== '结果命中')
          if (mzObj) {
            if (typeof mzObj.value === 'string') {
              mzObj.value = mzObj.value.replace(new RegExp('<em>', 'gm'), '').replace(new RegExp('</em>', 'gm'), '')
            } else if (Array.isArray(mzObj.value) && mzObj.value.length) {
              mzObj.value.forEach((val, i) => {
                const str = val.replace(new RegExp('<em>', 'gm'), '').replace(new RegExp('</em>', 'gm'), '')
                mzObj.value[i] = str
              })
            }
            obj.content.push(mzObj)
          }
        }
        return obj
      })
      return list
    },
    handleDownLoadReport(flag) {
      if (flag) {
        this.showLoadTwo = true
      } else {
        this.showLoadOne = true
      }
      const fullJudge = flag
      console.log('调用下载接口fullJudge:', fullJudge)
      const fileName = this.reportName && this.reportName !== '临时检索报告' ? this.reportName : this.tempTitle
      this.$axios
        .post('https://api.farbun.com/document/convertoffice/downSearchReport', {
          // judgeReports: this.judgeList,
          // lawReports: this.lawList,
          // criterionReports: this.criterionList,
          // knowledgeReports: this.knowledgeList,
          // contentReports: this.contentList,
          // anaReports: this.anaList,
          // textReport: this.wordReport.trim(),
          // userName: this.userName,
          // lawFirmName: this.lawFirmName,
          // dayTime: this.dayTime,
          // avatarUrl: this.avatarUrl,
          // fullJudge,
          // name: fileName,

          // 正式接口参数
          judgeReports: this.judgeList,
          lawReports: this.lawList,
          agreementReports: [],
          criterionReports: this.criterionList,
          knowledgeReports: this.knowledgeList,
          contentReports: this.contentList,
          anaReports: this.anaList,
          textReport: this.wordReport.trim(),
          userName: this.userName,
          lawFirmName: this.lawFirmName,
          dayTime: this.dayTime,
          fullJudge,
          name: fileName,
        })
        .then((res) => {
          if (res.data.code === '200' && res.data.data) {
            this.showLoadOne = false
            this.showLoadTwo = false
            const url = res.data.data
            console.log('ios***********', url)
            this.fbDownLoad(url, fileName)
            // this.fbDownLoad(path, fileName)
          } else {
            this.$notify('下载失败')
          }
          this.showDownload = false
        })
        .catch((error) => {
          this.showLoadOne = false
          this.showLoadTwo = false
          this.showDownload = false
          console.log('下载接失败-error:', error)
          this.$notify('下载失败')
        })
    },
    fbDownLoad(url, fileName) {
      const name = url.substring(url.lastIndexOf('/') + 1)
      const pathUrl = url.substring(0, url.lastIndexOf('/'))
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // 安卓
        try {
          console.log('android***********', url)
          // eslint-disable-next-line
          AND2JS.showFilePreview(url)
        } catch (err) {
          console.log(err, 'android方法调用失败')
        }
      } else if (ua === 'fb-flutter-Webview') {
        // flutter环境
        // eslint-disable-next-line
        try {
          // 之前的方法
          // window.fbDownloadFile.postMessage(url)
          // 新改的方法
          window.showFilePreview.postMessage(
            JSON.stringify({
              filePath: pathUrl,
              fileName: name,
            })
          )
        } catch (error) {
          console.log(error, 'fbDownloadFile.postMessage')
        }
      } else if (ua === this.$ua.i) {
        // ios环境
        // eslint-disable-next-line
        try {
          console.log('ios***********', url)
          window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
        } catch (err) {
          console.log(err, 'ios方法调用失败')
        }
      } else {
        console.log('非原生端', url)
        window.open(url)
      }
    },
    // 展示历史面板
    handleShowHistory() {
      this.showMenuList = false
      this.showHistory = true
    },
  },
  watch: {
    // allItemChecked(val) {
    //   console.log('监听勾选', val)
    //   this.judgeList.forEach(item => {
    //     item.checked = val
    //   })
    // },
    /* reportId(val) {
      console.log(val, '==========>>>watch----reportId')
      if (val) {
        this.$axios
          .get(`${this.$base}/management/collection/${val}`)
          .then(res => {
            if (res.data.data.parent) {
              this.relateCaseName =
                res.data.data.parent.name === ('检索报告' || '分享报告')
                  ? '暂无关联案件'
                  : res.data.data.parent.name
            } else {
              this.relateCaseName = '暂无关联案件'
            }
          })
      } else {
        this.relateCaseName = ''
      }
    } */
  },
}
</script>

<style lang="stylus" scope>
.head-left
  display flex
  line-height 1.173333rem;
  position relative
  .user-time,.collect-box
    margin-left 0.1rem
    font-size 12px
    cursor pointer
  .switch-collect
    color #409EFF
    font-size 12px
    margin-left 0.15rem
    font-weight normal
    cursor pointer
  .relate-case
    position absolute
    left 30px
    bottom 0
    font-size 10px
    color #ccc
    line-height 14px
.header-menu
  font-size 12px
  .menu-text
    color #409EFF
    margin-right 0.2rem
    cursor pointer
   & .morelist
      position relative
      & .menu-list
        position absolute
        z-index 10
        right -5px
        top 20px
        width 50px
        box-sizing border-box
        border-radius 3px
        padding 0 5px
        line-height 30px
        background #fff
        box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
        display flex
        flex-direction column
        justify-content center
.search-report,.edit-report
  width 100vw
  height calc(100vh - 40px)
  box-sizing border-box
  position absolute
  top 40px
  overflow-y scroll
  .print-fengmian
    width 100vw
    height 100vh
    background #ffffff
    font-weight bold
    padding-bottom 20px
    .fengmian-logo
      width 40px
      height 40px
    .fengmian-lawfirm
      width 100%
      text-align center
      margin 50px auto
      font-size 28px
      display flex
      justify-content center
      align-items center
    .fengmian-report
      width 50px
      height 160px
      margin 0 auto
      margin-top 80px
      display flex
      flex-direction column
      justify-content space-around
      align-items center
      font-size 20px
    .fengmian-userinfo
      width 90%
      margin 100px auto
      font-weight normal
      display flex
      justify-content center
      align-items center
      font-size 20px
  .print-body
    width 100%
    min-height 100vh
    background #ffffff
    padding-bottom 40px
    .add-content
      .add-text
        padding 10px
        text-align right
        line-height 20px
        color #ccc
        cursor pointer
    .add-area
      color #ccc
      padding 5px
      .van-cell
        border 1px solid #ccc
    .mul-btns
      padding-right:10px
      display:flex;
      align-items:center;
      color #409EFF
    h3
      font-weight bold
      font-size 16px
      height 42px
      line-height 42px
      padding-left 10px
  & .content-item
    padding 5px 12px
    line-height 18px
    & .focus-par
      min-height 42px
      display flex
      align-items center
    & .content-title
      width calc(100vw - 20px)
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  & .judgement-item, .item
    position relative
    & .title
      width 100%
      padding 10px
      overflow hidden
      cursor pointer
      display flex
      flex-direction row
      & .text
        font-size 16px
        color #333333
        float left
        font-weight 600
        flex 1
      & .CanNot
        height 21px
        box-sizing border-box
        background #ff9c39
        color #fff
        line-height 21px
        padding 0 5px
        border-radius 3px
    & .showrow
      width 100%
      padding 10px
      padding-top 0
      overflow hidden
      & .text
        font-size 12px
        color #999999
        font-weight normal
        float left
      & .open
        float left
        font-size 12px
        color #999999
        font-weight normal
        overflow hidden
        margin-right 10px
        cursor pointer
        & i
          font-size 14px
        & .tip
          overflow hidden
          padding 3px 5px 3px
          background-color #ff9c39
          font-size 14px
          color white
          border-radius 3px
    & .zkcontent
      width 100%
      padding 0 10px
      margin-bottom 10px
      & h4
        font-size 14px
      & span
        font-size 12px
.bottom-menu
  position fixed
  background #fff
  cursor pointer
  width 100vw
  max-height 80vh
  left 0
  right 0
  bottom 0
  border-top 1px solid #ccc
  z-index 999
  .menu-content
    background #fff
    height 100%
    padding 5px
    box-sizing border-box
    position relative
    & .van-overlay
      position absolute
    & .van-dialog
      position absolute
      background none
      width 50px
    & #reportBodyTitle,#reportBodyMulu,.fengmianTittleWraper
      font-weight normal
      height 40px
      line-height 40px
      font-size 14px
      & .van-icon.iconfont
        font-size 0.8rem !important
        vertical-align middle
      & .switch-fm
        font-size 14px
        cursor pointer
        color #409EFF
        font-weight normal
      #fengmianTittle
        padding 0 18px
        font-size 14px
        font-weight normal
    & #reportBodyMulu
      font-weight normal
      display flex
      color #666
      justify-content space-between
      .mulu-text
        flex 1
      .all-clear
        width 80px
        font-size 14px
        text-align right
        cursor pointer
        color #409EFF
        font-weight normal
    .report-mulu
      width 100%
      max-height 400px
      overflow-y auto
    & h3
      font-weight normal
      font-size 14px
    & .mul-item>div
      display flex
      height 26px
      line-height 26px
      & span
        flex 9
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
      &>i
        flex 1
        text-align center
        font-size 18px
        color #ccc
.bottom-wrapper
  position fixed
  left 0
  right 0
  bottom 0
  width 100%
  z-index 99
  height 40px
  background #fff
  .switch-fm
    float right
    width 80px
    height 40px
    text-align center
    line-height 40px
    color #409EFF
    cursor pointer
  .open-panel
    float left
    width 60px
    height 40px
    text-align center
    line-height 40px
    color #409EFF
    cursor pointer
  .clear-collect
    float right
    width 40px
    height 40px
    text-align center
    line-height 40px
    color #409EFF
    cursor pointer
.nav
  width 100%
  height 100%
  position absolute
  left 0px
  top 0px
  background-color #ffffff
  z-index 10000000
  overflow-x hidden
.add-tag:before
  cursor pointer
  content '·'
  font-weight bold
.active-title
  color #409EFF
  span.bgzw
    color #409eff
span.bgzw
  color #000
.full-screen-portal-comp
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  z-index 2998
.switch_dialog .van-dialog__content
  position relative
  .close_btn
    position: absolute;
    right: 20px;
    top: -0.64rem;
    font-size: 18px;
    width: 0.64rem;
    height: 0.64rem;
    line-height: 0.64rem;
    text-align: center;
.notify
  position fixed
  top 0
  left 0
  width 100vw
  margin 0 auto
  z-index 99999
  box-sizing border-box
  color #fff
  font-size 0.4rem
  line-height 1.2rem
  text-align center
  transition-duration 0.2s
  &.success
    background #67C23A
  &.error
    background #ee0a24
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
<style lang="stylus" scope>
.report
  .loading-dialog
    background none
    width 50px
  .download-dialog
    width 80%
    .van-dialog__message
      display flex
      justify-content space-around

.cancel
  cursor pointer
  position absolute
  top 10px
  right 15px
</style>
