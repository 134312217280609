var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "history-wraper", on: { scroll: _vm.handleScroll } },
    [
      _vm.changeReport
        ? _c(
            "div",
            [
              _c("van-field", {
                attrs: { clearable: "" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleKeyEnter.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.changeReportName,
                  callback: function ($$v) {
                    _vm.changeReportName = $$v
                  },
                  expression: "changeReportName",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "van-action-sheet__item van-hairline--top history_title",
                },
                [
                  _c("div", [
                    _c("span", [_vm._v("历史记录    ")]),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.reportId,
                            expression: "reportId",
                          },
                        ],
                        staticClass: "switch-collect",
                        on: { click: _vm.emitSwitch },
                      },
                      [_vm._v("新收集")]
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.reportList.length > 0,
                          expression: "reportList.length > 0",
                        },
                      ],
                      staticStyle: { color: "#409eff" },
                      on: { click: _vm.handleClear },
                    },
                    [_vm._v("清空")]
                  ),
                ]
              ),
              _vm._l(_vm.reportList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "van-action-sheet__item van-hairline--top item",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "van-action-sheet__name history_item",
                        class: _vm.reportId === item.id ? "activeClass" : "",
                      },
                      [
                        _c("div", { staticClass: "left" }, [
                          _c("div", { staticClass: "up" }, [
                            _c("span", { staticClass: "index" }, [
                              _vm._v(_vm._s(index + 1) + "  "),
                            ]),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  item.name && item.name.length > 20
                                    ? item.name.substr(0, 20) + "..."
                                    : item.name
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "down" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  item.label
                                    ? item.label.length > 16
                                      ? item.label.substr(0, 16) + "..."
                                      : item.label
                                    : "暂无关联案件"
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "middle" }, [
                          _c("i", {
                            staticClass: "iconfont icon-edit",
                            on: {
                              click: function ($event) {
                                return _vm.handChangName(item)
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleDel(item, index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.reportId !== item.id,
                                  expression: "reportId !== item.id",
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.handleOpen(item)
                                },
                              },
                            },
                            [_vm._v("打开")]
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              _vm.showLoading
                ? _c("fb-loading", { attrs: { loading: _vm.showLoading } })
                : _vm._e(),
            ],
            2
          ),
      _c(
        "van-dialog",
        {
          attrs: { title: "", "show-cancel-button": "", overlay: true },
          on: { confirm: _vm.clearConfirm },
          model: {
            value: _vm.showClearDelDialog,
            callback: function ($$v) {
              _vm.showClearDelDialog = $$v
            },
            expression: "showClearDelDialog",
          },
        },
        [
          _c("div", { staticClass: "van-dialog__content" }, [
            _c("div", { staticClass: "van-dialog__message" }, [
              _vm._v(" 确定删除当前页面所有的检索报告？ "),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }