var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "report", staticClass: "report" },
    [
      !_vm.editMode
        ? _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.detailshow.show && !_vm.detailshow.flag,
                    expression: "!detailshow.show && !detailshow.flag",
                  },
                ],
              },
              [
                _c(
                  "fb-header",
                  { attrs: { slot: "header", fixed: "" }, slot: "header" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "head-left",
                        attrs: { slot: "left" },
                        slot: "left",
                      },
                      [
                        _c("fb-button", {
                          attrs: {
                            icon: "back",
                            size: "small",
                            type: "primary",
                          },
                          on: { click: _vm.handleGoBack },
                        }),
                        _c("div", { staticClass: "collect-box" }, [
                          _c("span", [_vm._v("收集箱")]),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.reportCount)),
                          ]),
                        ]),
                        _vm.reportId
                          ? _c(
                              "div",
                              {
                                staticClass: "switch-collect",
                                on: { click: _vm.handleSwitch },
                              },
                              [_vm._v("新收集")]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "relate-case" }, [
                          _vm._v(_vm._s(_vm.relateCaseName)),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { attrs: { slot: "right" }, slot: "right" }, [
                      _c("div", { staticClass: "header-menu" }, [
                        _c(
                          "div",
                          {
                            staticClass: "menu-text",
                            on: { click: _vm.handleShowHistory },
                          },
                          [_vm._v("历史")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "menu-text",
                            on: { click: _vm.handleRelateCase },
                          },
                          [_c("span", [_vm._v("案件")])]
                        ),
                        !_vm.$route.query.isMiniApp
                          ? _c(
                              "div",
                              {
                                staticClass: "menu-text",
                                on: { click: _vm.handleShare },
                              },
                              [_c("span", [_vm._v("分享")])]
                            )
                          : _vm._e(),
                        _vm.showShare
                          ? _c(
                              "div",
                              {
                                staticClass: "menu-text",
                                on: { click: _vm.toShare },
                              },
                              [_c("span", [_vm._v("个人分享")])]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "menu-text morelist" }, [
                          !_vm.$route.query.isMiniApp
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showMenuList = !_vm.showMenuList
                                    },
                                  },
                                },
                                [_vm._v("更多")]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showMenuList,
                                  expression: "showMenuList",
                                },
                              ],
                              staticClass: "menu-list",
                            },
                            [
                              !_vm.$route.query.isMiniApp
                                ? _c(
                                    "span",
                                    { on: { click: _vm.clickDownload } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.isIOS ? "预览" : "下载")
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                { on: { click: _vm.handleSaveElse } },
                                [_vm._v("另存为")]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { ref: "searchReport", staticClass: "search-report" },
                  [
                    _c(
                      "div",
                      {
                        ref: "reportContainer",
                        staticClass: "report-container",
                        attrs: { id: "reportContainer" },
                      },
                      [
                        _vm.hasFirstPage
                          ? _c(
                              "div",
                              {
                                staticClass: "print-fengmian",
                                attrs: { id: "fengmian" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.blurInput.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "fengmian-logo" }),
                                _c(
                                  "div",
                                  { staticClass: "fengmian-lawfirm center" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.editLawFirmName,
                                            expression: "!editLawFirmName",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.clickLawFirmName.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.lawFirmName.length > 10
                                              ? _vm.lawFirmName.substr(0, 10) +
                                                  "..."
                                              : _vm.lawFirmName
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("van-field", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.editLawFirmName,
                                          expression: "editLawFirmName",
                                        },
                                      ],
                                      ref: "lawFirmNameInput",
                                      attrs: { placeholder: "请填写内容" },
                                      on: {
                                        blur: function ($event) {
                                          _vm.editLawFirmName = false
                                        },
                                      },
                                      model: {
                                        value: _vm.lawFirmName,
                                        callback: function ($$v) {
                                          _vm.lawFirmName = $$v
                                        },
                                        expression: "lawFirmName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._m(0),
                                _c(
                                  "div",
                                  { staticClass: "fengmian-userinfo" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "name" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.editUserName,
                                                expression: "!editUserName",
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickUserName.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.userName))]
                                        ),
                                        _c("van-field", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.editUserName,
                                              expression: "editUserName",
                                            },
                                          ],
                                          ref: "userNameInput",
                                          on: {
                                            blur: function ($event) {
                                              _vm.editUserName = false
                                            },
                                          },
                                          model: {
                                            value: _vm.userName,
                                            callback: function ($$v) {
                                              _vm.userName = $$v
                                            },
                                            expression: "userName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("span", [_vm._v("    ")]),
                                    _c("div", { staticClass: "time" }, [
                                      _vm._v(_vm._s(_vm.dayTime)),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "print-body",
                            attrs: { id: "reportBody" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "add-content",
                                attrs: { id: "addContent" },
                              },
                              [
                                _vm.showAddText
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "add-text",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            _vm.showAddText = false
                                          },
                                        },
                                      },
                                      [_vm._v("添加内容")]
                                    )
                                  : _c("div", { staticClass: "add-area" }, [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showAreaText,
                                              expression: "showAreaText",
                                            },
                                          ],
                                          staticStyle: { padding: "10px" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.focusTextInput.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.wordReport) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.showAreaText,
                                              expression: "!showAreaText",
                                            },
                                          ],
                                        },
                                        [
                                          _c("van-field", {
                                            ref: "textInput",
                                            attrs: {
                                              autosize: "",
                                              type: "textarea",
                                              placeholder: "请填写内容",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                _vm.showAreaText = true
                                              },
                                            },
                                            model: {
                                              value: _vm.wordReport,
                                              callback: function ($$v) {
                                                _vm.wordReport = $$v
                                              },
                                              expression: "wordReport",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.judgeList.length,
                                    expression: "judgeList.length",
                                  },
                                ],
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    attrs: { id: "judge" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.clickJudge.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("判例")]
                                ),
                                !_vm.$route.query.isMiniApp
                                  ? _c("div", { staticClass: "mul-btns" }, [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleMulDownload()
                                            },
                                          },
                                        },
                                        [_vm._v("批量下载")]
                                      ),
                                      _vm._v("     "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleMulShare(
                                                "judgeReports"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("批量分享")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._l(_vm.judgeList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.doc_id,
                                  staticClass: "item van-hairline--top-bottom",
                                  attrs: { id: item.doc_id },
                                },
                                [
                                  _c("judgementItem", {
                                    ref: "judgeitem",
                                    refInFor: true,
                                    attrs: {
                                      itemindex: index,
                                      item: item,
                                      isPreview: false,
                                    },
                                    on: {
                                      move: _vm.handleMove,
                                      delItem: _vm.handleDelItem,
                                      toDetail: _vm.toJudgeDetail,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.lawList.length,
                                    expression: "lawList.length",
                                  },
                                ],
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    attrs: { id: "law" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.clickLaw.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("法律")]
                                ),
                                !_vm.$route.query.isMiniApp
                                  ? _c("div", { staticClass: "mul-btns" }, [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleMulShare(
                                                "lawReports"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("批量分享")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._l(_vm.lawList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.law_id,
                                  staticClass: "item van-hairline--top-bottom",
                                  attrs: { id: item.law_id },
                                },
                                [
                                  _c("lawItem", {
                                    ref: "lawitem",
                                    refInFor: true,
                                    attrs: {
                                      itemindex: index,
                                      item: item,
                                      contentType: "lawSearch",
                                      isPreview: false,
                                    },
                                    on: {
                                      move: _vm.handleMove,
                                      delItem: _vm.handleDelItem,
                                      toDetail: _vm.toLawDetail,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            _c(
                              "h3",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.knowledgeList.length,
                                    expression: "knowledgeList.length",
                                  },
                                ],
                                attrs: { id: "knowledge" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.blurInput.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("知识")]
                            ),
                            _vm._l(_vm.knowledgeList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.url,
                                  staticClass: "item van-hairline--top-bottom",
                                  attrs: { id: item.url },
                                },
                                [
                                  _c("Item", {
                                    ref: "knowledgeitem",
                                    refInFor: true,
                                    attrs: {
                                      itemindex: index,
                                      item: item,
                                      isPreview: false,
                                      contentType: "knowledgeSearch",
                                    },
                                    on: {
                                      move: _vm.handleMove,
                                      delItem: _vm.handleDelItem,
                                      toDetail: _vm.toKnowledgeDetail,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            _c(
                              "h3",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.criterionList.length,
                                    expression: "criterionList.length",
                                  },
                                ],
                                attrs: { id: "criterion" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.blurInput.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("标准")]
                            ),
                            _vm._l(_vm.criterionList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.nsId,
                                  staticClass: "item van-hairline--top-bottom",
                                  attrs: { id: item.nsId },
                                },
                                [
                                  _c("Item", {
                                    ref: "criterionitem",
                                    refInFor: true,
                                    attrs: {
                                      itemindex: index,
                                      item: item,
                                      isPreview: false,
                                      contentType: "criterionSearch",
                                    },
                                    on: {
                                      move: _vm.handleMove,
                                      delItem: _vm.handleDelItem,
                                      toDetail: _vm.toCriterionDetail,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            _c(
                              "h3",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.agreementList.length,
                                    expression: "agreementList.length",
                                  },
                                ],
                                attrs: { id: "agreement" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.blurInput.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("合同")]
                            ),
                            _vm._l(_vm.agreementList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "item van-hairline--top-bottom",
                                  attrs: { id: item.id },
                                },
                                [
                                  _c("Item", {
                                    ref: "agreementitem",
                                    refInFor: true,
                                    attrs: {
                                      itemindex: index,
                                      item: item,
                                      isPreview: false,
                                      contentType: "agreementSearch",
                                    },
                                    on: {
                                      move: _vm.handleMove,
                                      delItem: _vm.handleDelItem,
                                      toDetail: _vm.toAgreementDetail,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            _c(
                              "h3",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.contentList.length +
                                      _vm.anaList.length,
                                    expression:
                                      "contentList.length + anaList.length",
                                  },
                                ],
                                attrs: { id: "content" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.blurInput.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("正文")]
                            ),
                            _c(
                              "div",
                              [
                                _vm._l(_vm.contentList, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "content-item",
                                      attrs: { id: `content-${item.id}` },
                                    },
                                    [
                                      _c(
                                        "van-swipe-cell",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "left",
                                                fn: function () {
                                                  return [
                                                    _c("van-button", {
                                                      attrs: {
                                                        square: "",
                                                        type: "danger",
                                                        text: "删 除",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDelItem(
                                                            {
                                                              listType:
                                                                "contentList",
                                                              reportType:
                                                                "contentReports",
                                                              showConfirm: true,
                                                              index,
                                                            }
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "right",
                                                fn: function () {
                                                  return [
                                                    _c("van-button", {
                                                      attrs: {
                                                        square: "",
                                                        type: "primary",
                                                        text: "上移",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleMove(
                                                            {
                                                              direction: "up",
                                                              listType:
                                                                "contentList",
                                                              reportType:
                                                                "contentReports",
                                                              item,
                                                              index,
                                                            }
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("van-button", {
                                                      attrs: {
                                                        square: "",
                                                        type: "primary",
                                                        text: "下移",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleMove(
                                                            {
                                                              direction: "down",
                                                              listType:
                                                                "contentList",
                                                              reportType:
                                                                "contentReports",
                                                              item,
                                                              index,
                                                            }
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "content-title" },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                        ]
                                      ),
                                      _vm._l(item.arr, function (ite, ind) {
                                        return _c(
                                          "div",
                                          { key: ind },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      ite.name !== "正文" &&
                                                      item.isLaw,
                                                    expression:
                                                      "ite.name !== '正文' && item.isLaw",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(ite.name) + " "
                                                ),
                                              ]
                                            ),
                                            _c("p", {
                                              domProps: {
                                                innerHTML: _vm._s(ite.text),
                                              },
                                            }),
                                            _vm._l(ite.arr, function (v, i) {
                                              return _c("div", { key: i }, [
                                                _c("p", {
                                                  domProps: {
                                                    innerHTML: _vm._s(v.text),
                                                  },
                                                }),
                                              ])
                                            }),
                                          ],
                                          2
                                        )
                                      }),
                                      _vm._l(item.textArr, function (ite, idx) {
                                        return _c(
                                          "div",
                                          { key: `${index}-${idx}` },
                                          [
                                            typeof item === "string"
                                              ? _c("p", {
                                                  domProps: {
                                                    innerHTML: _vm._s(ite),
                                                  },
                                                })
                                              : _c("div", [
                                                  _c("h4", [
                                                    _vm._v(_vm._s(ite.name)),
                                                  ]),
                                                  _c("p", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        ite.text
                                                      ),
                                                    },
                                                  }),
                                                ]),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  )
                                }),
                                _vm._l(_vm.anaList, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: `ana-${index}`,
                                      staticClass: "content-item",
                                    },
                                    [
                                      item.type === "law"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-item",
                                              attrs: { id: item.id },
                                            },
                                            [
                                              _c(
                                                "van-swipe-cell",
                                                {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "left",
                                                        fn: function () {
                                                          return [
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "danger",
                                                                text: "删 除",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDelItem(
                                                                      {
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        showConfirm: true,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "right",
                                                        fn: function () {
                                                          return [
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "primary",
                                                                text: "上移",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleMove(
                                                                      {
                                                                        direction:
                                                                          "up",
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        item,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "primary",
                                                                text: "下移",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleMove(
                                                                      {
                                                                        direction:
                                                                          "down",
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        item,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "content-title",
                                                    },
                                                    [_vm._v(_vm._s(item.title))]
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                item.anaArr,
                                                function (ite, ind) {
                                                  return _c(
                                                    "div",
                                                    { key: ind },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(ite.name) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("p", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            ite.text
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : item.type === "appeal"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-item",
                                              attrs: { id: item.id },
                                            },
                                            [
                                              _c(
                                                "van-swipe-cell",
                                                {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "left",
                                                        fn: function () {
                                                          return [
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "danger",
                                                                text: "删 除",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDelItem(
                                                                      {
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        showConfirm: true,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "right",
                                                        fn: function () {
                                                          return [
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "primary",
                                                                text: "上移",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleMove(
                                                                      {
                                                                        direction:
                                                                          "up",
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        item,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "primary",
                                                                text: "下移",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleMove(
                                                                      {
                                                                        direction:
                                                                          "down",
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        item,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "content-title",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.case_name)
                                                        ),
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.case_id)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._l(item.data, function (ite) {
                                                return _c(
                                                  "div",
                                                  { key: ite.title },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(ite.title) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _vm._l(
                                                      ite.data,
                                                      function (it) {
                                                        return _c(
                                                          "p",
                                                          {
                                                            key: it.toString(),
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(it) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              }),
                                            ],
                                            2
                                          )
                                        : item.type === "deem"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-item",
                                              attrs: { id: item.id },
                                            },
                                            [
                                              _c(
                                                "van-swipe-cell",
                                                {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "left",
                                                        fn: function () {
                                                          return [
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "danger",
                                                                text: "删 除",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDelItem(
                                                                      {
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        showConfirm: true,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "right",
                                                        fn: function () {
                                                          return [
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "primary",
                                                                text: "上移",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleMove(
                                                                      {
                                                                        direction:
                                                                          "up",
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        item,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "primary",
                                                                text: "下移",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleMove(
                                                                      {
                                                                        direction:
                                                                          "down",
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        item,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "content-title",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.case_name)
                                                        ),
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.case_id)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("p", [
                                                _vm._v(_vm._s(item.text)),
                                              ]),
                                            ],
                                            1
                                          )
                                        : item.type === "focus"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-item",
                                              attrs: { id: item.id },
                                            },
                                            [
                                              _c(
                                                "van-swipe-cell",
                                                {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "right",
                                                        fn: function () {
                                                          return [
                                                            _c("van-button", {
                                                              attrs: {
                                                                square: "",
                                                                type: "danger",
                                                                text: "删 除",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDelItem(
                                                                      {
                                                                        listType:
                                                                          "anaList",
                                                                        reportType:
                                                                          "anaReports",
                                                                        showConfirm: true,
                                                                        index,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("p", {
                                                    staticClass: "focus-par",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.title
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPanel,
                        expression: "showPanel",
                      },
                    ],
                    staticClass: "bottom-menu",
                  },
                  [
                    _c("div", { staticClass: "menu-content" }, [
                      _c("div", { attrs: { id: "reportBodyMulu" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "mulu-text",
                            on: {
                              click: function ($event) {
                                _vm.showPanel = false
                              },
                            },
                          },
                          [_vm._v("目录")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "all-clear",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleClear.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("全部清空")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "fengmianTittleWraper",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            color: "#666",
                          },
                        },
                        [
                          _c(
                            "h3",
                            {
                              attrs: { id: "fengmianTittle" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSwithTitle(
                                    "fengmian",
                                    "fengmianTittle"
                                  )
                                },
                              },
                            },
                            [_vm._v("封面")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "switch-fm",
                              on: { click: _vm.handleSwitchFirstPage },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.hasFirstPage ? "隐藏封面" : "显示封面"
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { attrs: { id: "addContentTitle" } }),
                      _c(
                        "div",
                        { staticClass: "report-mulu" },
                        [
                          _c(
                            "van-collapse",
                            {
                              model: {
                                value: _vm.activeNames,
                                callback: function ($$v) {
                                  _vm.activeNames = $$v
                                },
                                expression: "activeNames",
                              },
                            },
                            [
                              _c(
                                "van-collapse-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.judgeList.length,
                                      expression: "judgeList.length",
                                    },
                                  ],
                                  attrs: { name: "1" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "h3",
                                              {
                                                attrs: { id: "judgeTitle" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleSwithTitle(
                                                      "judge",
                                                      "judgeTitle"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("判例")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3495303536
                                  ),
                                },
                                _vm._l(_vm.judgeList, function (item, index1) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.doc_id,
                                      staticClass: "mul-item",
                                      attrs: { id: `judge-${index1}` },
                                    },
                                    [
                                      _c("muluItem", {
                                        attrs: {
                                          checkedAll: _vm.checkedAll,
                                          item: item,
                                          index: index1,
                                          idName: item.doc_id,
                                          currentIdName: `judge-${index1}`,
                                          type: "judge",
                                        },
                                        on: {
                                          delItem: _vm.handleDelItem,
                                          switch: _vm.handleSwith,
                                          changeItemCheck: _vm.handleChange,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "van-collapse-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.lawList.length,
                                      expression: "lawList.length",
                                    },
                                  ],
                                  attrs: { name: "2" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "h3",
                                              {
                                                attrs: { id: "lawTitle" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleSwithTitle(
                                                      "law",
                                                      "lawTitle"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("法律")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1512800706
                                  ),
                                },
                                _vm._l(_vm.lawList, function (item, index2) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.law_id,
                                      staticClass: "mul-item",
                                      attrs: { id: `law-${index2}` },
                                    },
                                    [
                                      _c("muluItem", {
                                        attrs: {
                                          checkedAll: _vm.checkedAll,
                                          item: item,
                                          index: index2,
                                          idName: item.law_id,
                                          currentIdName: `law-${index2}`,
                                          type: "law",
                                        },
                                        on: {
                                          delItem: _vm.handleDelItem,
                                          switch: _vm.handleSwith,
                                          changeItemCheck: _vm.handleChange,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "van-collapse-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.knowledgeList.length,
                                      expression: "knowledgeList.length",
                                    },
                                  ],
                                  attrs: { name: "3" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "h3",
                                              {
                                                attrs: { id: "knowledgeTitle" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleSwithTitle(
                                                      "knowledge",
                                                      "knowledgeTitle"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("知识")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3121862391
                                  ),
                                },
                                _vm._l(
                                  _vm.knowledgeList,
                                  function (item, index3) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.url,
                                        staticClass: "mul-item",
                                        attrs: { id: `knowledge-${index3}` },
                                      },
                                      [
                                        _c("muluItem", {
                                          attrs: {
                                            item: item,
                                            index: index3,
                                            idName: item.url,
                                            currentIdName: `knowledge-${index3}`,
                                            type: "knowledge",
                                          },
                                          on: {
                                            delItem: _vm.handleDelItem,
                                            switch: _vm.handleSwith,
                                            changeItemCheck: _vm.handleChange,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "van-collapse-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.criterionList.length,
                                      expression: "criterionList.length",
                                    },
                                  ],
                                  attrs: { name: "4" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "h3",
                                              {
                                                attrs: { id: "criterionTitle" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleSwithTitle(
                                                      "criterion",
                                                      "criterionTitle"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("标准")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2064615764
                                  ),
                                },
                                _vm._l(
                                  _vm.criterionList,
                                  function (item, index4) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.nsId,
                                        staticClass: "mul-item",
                                        attrs: { id: `criterion-${index4}` },
                                      },
                                      [
                                        _c("muluItem", {
                                          attrs: {
                                            item: item,
                                            index: index4,
                                            idName: item.nsId,
                                            currentIdName: `criterion-${index4}`,
                                            type: "criterion",
                                          },
                                          on: {
                                            delItem: _vm.handleDelItem,
                                            switch: _vm.handleSwith,
                                            changeItemCheck: _vm.handleChange,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "van-collapse-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.agreementList.length,
                                      expression: "agreementList.length",
                                    },
                                  ],
                                  attrs: { name: "6" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "h3",
                                              {
                                                attrs: { id: "agreementTitle" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleSwithTitle(
                                                      "agreement",
                                                      "agreementTitle"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("合同")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    737933732
                                  ),
                                },
                                _vm._l(
                                  _vm.agreementList,
                                  function (item, index6) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass: "mul-item",
                                        attrs: { id: `agreement-${index6}` },
                                      },
                                      [
                                        _c("muluItem", {
                                          attrs: {
                                            item: item,
                                            index: index6,
                                            idName: item.id,
                                            currentIdName: `agreement-${index6}`,
                                            type: "agreement",
                                          },
                                          on: {
                                            delItem: _vm.handleDelItem,
                                            switch: _vm.handleSwith,
                                            changeItemCheck: _vm.handleChange,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "van-collapse-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.contentList.length +
                                        _vm.anaList.length,
                                      expression:
                                        "contentList.length + anaList.length",
                                    },
                                  ],
                                  attrs: { name: "5" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "h3",
                                              {
                                                attrs: { id: "contentTitle" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleSwithTitle(
                                                      "content",
                                                      "contentTitle"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("正文")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    165974315
                                  ),
                                },
                                [
                                  _vm._l(
                                    _vm.contentList,
                                    function (item, index5) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass: "mul-item",
                                          attrs: { id: `content-${index5}` },
                                        },
                                        [
                                          _c("muluItem", {
                                            attrs: {
                                              item: item,
                                              index: index5,
                                              idName: `content-${item.id}`,
                                              currentIdName: `content-${index5}`,
                                              type: "content",
                                            },
                                            on: {
                                              delItem: _vm.handleDelItem,
                                              switch: _vm.handleSwith,
                                              changeItemCheck: _vm.handleChange,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._l(_vm.anaList, function (item, index7) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass: "mul-item",
                                        attrs: { id: `ana-${index7}` },
                                      },
                                      [
                                        _c("muluItem", {
                                          attrs: {
                                            item: item,
                                            idName: `${item.id}`,
                                            currentIdName: `ana-${index7}`,
                                            index: index7,
                                            type: "ana",
                                          },
                                          on: {
                                            delItem: _vm.handleDelItem,
                                            switch: _vm.handleSwith,
                                            changeItemCheck: _vm.handleChange,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        : _c(
            "div",
            [
              _c(
                "fb-header",
                { attrs: { slot: "header", fixed: "" }, slot: "header" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "head-left",
                      attrs: { slot: "left" },
                      slot: "left",
                    },
                    [
                      _c("fb-button", {
                        attrs: { icon: "back", size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.editMode = false
                          },
                        },
                      }),
                      _c("div", { staticClass: "collect-box" }, [
                        _c("span", [_vm._v("退出编辑")]),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { attrs: { slot: "right" }, slot: "right" }, [
                    _c("div", { staticClass: "header-menu" }, [
                      _c(
                        "div",
                        {
                          staticClass: "menu-text",
                          on: { click: _vm.handleClear },
                        },
                        [_vm._v("清空")]
                      ),
                      !_vm.$route.query.isMiniApp
                        ? _c(
                            "div",
                            {
                              staticClass: "menu-text",
                              on: { click: _vm.handleShare },
                            },
                            [_vm._v("分享")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "edit-report" }, [_c("editorMode")], 1),
            ],
            1
          ),
      _vm.detailshow.show
        ? _c(
            "div",
            { staticClass: "nav", attrs: { id: "nav" } },
            [
              _c("lawDetail", {
                attrs: {
                  id: _vm.detailshow.case_id,
                  title: _vm.detailshow.title,
                  catlog: _vm.detailshow.catlog,
                  words: _vm.detailshow.words,
                },
                on: { changeflag: _vm.changeflag },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.detailshow.flag
        ? _c(
            "div",
            { staticClass: "nav", attrs: { id: "nav" } },
            [
              _c("percentDetail", {
                attrs: {
                  id: _vm.detailshow.case_id,
                  words: _vm.detailshow.words,
                  titleName: _vm.detailshow.name,
                },
                on: { changeflag: _vm.changeshow },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.editMode && !_vm.showCaseList
        ? _c("div", { staticClass: "bottom-wrapper" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showPanel,
                    expression: "!showPanel",
                  },
                ],
                staticClass: "open-panel",
                on: {
                  click: function ($event) {
                    _vm.showPanel = true
                  },
                },
              },
              [_vm._v("目录")]
            ),
            _c(
              "div",
              { staticClass: "clear-collect", on: { click: _vm.handleClear } },
              [_vm._v("清空")]
            ),
            _c(
              "div",
              {
                staticClass: "switch-fm",
                on: { click: _vm.handleSwitchFirstPage },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.hasFirstPage ? "不显示封面" : "显示封面") +
                    " "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.favVisible,
              expression: "favVisible",
            },
          ],
          staticClass: "full-screen-portal-comp",
        },
        [
          _c("skyDrive", {
            attrs: {
              listid: _vm.favFolderId,
              Type: "SEARCHREPORT",
              modulename: "分析报告",
              listname: "检索报告",
              summary: _vm.summary,
            },
            on: { CancelCollect: _vm.cancelFav, getFold: _vm.useFav },
          }),
        ],
        1
      ),
      _vm.showCaseList
        ? _c("relateCase", {
            attrs: {
              reportName: _vm.reportName,
              tempTitle: _vm.tempTitle,
              type: _vm.moveFileType,
              userId: _vm.userId,
            },
            on: {
              select: _vm.onSelectCase,
              changeName: _vm.handleChangeName,
              cancel: _vm.onCancelSelect,
            },
          })
        : _vm._e(),
      _c(
        "van-action-sheet",
        {
          attrs: { title: "", round: false },
          model: {
            value: _vm.showHistory,
            callback: function ($$v) {
              _vm.showHistory = $$v
            },
            expression: "showHistory",
          },
        },
        [
          _vm.showHistory
            ? _c("history", {
                attrs: { reportId: _vm.reportId },
                on: {
                  open: _vm.handleOpen,
                  switch: _vm.handleSwitch,
                  init: _vm.handleInit,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "van-dialog",
        {
          attrs: { title: "", "show-cancel-button": "", overlay: true },
          on: { confirm: _vm.handleDelConfirm, cancel: _vm.handleDelCancel },
          model: {
            value: _vm.showDelDialog,
            callback: function ($$v) {
              _vm.showDelDialog = $$v
            },
            expression: "showDelDialog",
          },
        },
        [
          _c("div", { staticClass: "van-dialog__content" }, [
            _c("div", { staticClass: "van-dialog__message" }, [
              _vm._v("确定删除这条报告内容吗？"),
            ]),
          ]),
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { title: "", "show-cancel-button": "", overlay: true },
          on: { confirm: _vm.allDelConfirm },
          model: {
            value: _vm.showAllDelDialog,
            callback: function ($$v) {
              _vm.showAllDelDialog = $$v
            },
            expression: "showAllDelDialog",
          },
        },
        [
          _c("div", { staticClass: "van-dialog__content" }, [
            _c("div", { staticClass: "van-dialog__message" }, [
              _vm._v("删除后，数据将无法恢复。继续删除？"),
            ]),
          ]),
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: {
            className: "switch_dialog",
            title: "是否保存当前收集内容？",
            "show-confirm-button": false,
            overlay: true,
          },
          model: {
            value: _vm.showSwitchDialog,
            callback: function ($$v) {
              _vm.showSwitchDialog = $$v
            },
            expression: "showSwitchDialog",
          },
        },
        [
          _c(
            "span",
            { staticClass: "close_btn", on: { click: _vm.closeDialog } },
            [_vm._v("x")]
          ),
          _c("div", { staticClass: "van-hairline--top van-dialog__footer" }, [
            _c(
              "button",
              {
                staticClass:
                  "van-button van-button--default van-button--large van-dialog__cancel",
                on: { click: _vm.cancelSwitch },
              },
              [
                _c("div", { staticClass: "van-button__content" }, [
                  _c("span", { staticClass: "van-button__text" }, [
                    _vm._v("否"),
                  ]),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "van-button van-button--default van-button--large van-dialog__confirm van-hairline--left",
                on: { click: _vm.confirmSwitch },
              },
              [
                _c("div", { staticClass: "van-button__content" }, [
                  _c("span", { staticClass: "van-button__text" }, [
                    _vm._v("是"),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "van-dialog",
        {
          staticClass: "loading-dialog",
          attrs: { "show-confirm-button": false, overlay: true },
          model: {
            value: _vm.showfullLoading,
            callback: function ($$v) {
              _vm.showfullLoading = $$v
            },
            expression: "showfullLoading",
          },
        },
        [_c("van-loading")],
        1
      ),
      _c(
        "van-dialog",
        {
          staticClass: "download-dialog",
          attrs: {
            title: "准备下载，请确认",
            "show-confirm-button": false,
            overlay: true,
          },
          model: {
            value: _vm.showDownload,
            callback: function ($$v) {
              _vm.showDownload = $$v
            },
            expression: "showDownload",
          },
        },
        [
          _c("div", { staticClass: "cancel", on: { click: _vm.cancel } }, [
            _vm._v("x"),
          ]),
          _c("div", { staticClass: "van-dialog__content" }, [
            _c(
              "div",
              { staticClass: "van-dialog__message" },
              [
                _c(
                  "van-button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                      loading: _vm.showLoadOne,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDownLoadReport(false)
                      },
                    },
                  },
                  [_vm._v("只下载原报告")]
                ),
                _c(
                  "van-button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                      loading: _vm.showLoadTwo,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDownLoadReport(true)
                      },
                    },
                  },
                  [_vm._v("下载原报告加判例全文")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.actionNotifyVisible,
                expression: "actionNotifyVisible",
              },
            ],
            class: { notify: true, [_vm.actionNotify.type]: true },
          },
          [_vm._v(" " + _vm._s(_vm.actionNotify.msg) + " ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fengmian-report" }, [
      _c("span", [_vm._v("检")]),
      _c("span", [_vm._v("索")]),
      _c("span", [_vm._v("报")]),
      _c("span", [_vm._v("告")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }