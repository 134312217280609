<template>
  <div class="relate">
    <div class="relate-head">
      <i class="iconfont icon-back" @click="handleCancel"></i>
      <van-field
        ref="changNameInput"
        v-if="changeName"
        v-model="currentName"
        @keydown.enter.native="handleSureChange"
      >
        <template #right-icon>
          <span @click="handleSureChange">确认</span>
        </template>
      </van-field>
      <span v-else
        >&nbsp;&nbsp;将<span class="file-name" @click="clickChangeName"
          >&nbsp;{{
            currentName.length > 20
              ? currentName.substr(0, 20) + '...'
              : currentName
          }}&nbsp;</span
        >{{ saveMessage }}</span
      >
    </div>
    <div class="content-wrapper" @scroll="handleScroll">
      <div class="content-list">
        <div class="search-head">
          <div v-if="selectItem" class="select-name">
            <span>首页 / </span>
            <span>{{ selectItem.name }}</span>
          </div>
          <van-field
            v-else
            v-model="search"
            :placeholder="placeholder"
            clearable
          >
            <template #right-icon>
              <i
                class="iconfont icon-search right-icon"
                @click="handleSearch"
              ></i>
            </template>
          </van-field>
        </div>
        <div
          v-for="(item, index) in relateCaseList"
          :key="index"
          class="van-action-sheet__item van-hairline--top content-item"
        >
          <div class="van-action-sheet__name item_name">
            <svg
              t="1607483501714"
              class="icon"
              viewBox="0 0 1196 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="86077"
              width="30"
              height="30"
            >
              <path
                d="M69.234008 425.768421L62.186235 128.518219a64.259109 64.259109 0 0 1 64.259109-66.331984h258.280162a64.673684 64.673684 0 0 1 44.774089 18.241295l144.272065 142.199191h497.489878a63.844534 63.844534 0 0 1 63.42996 63.844534v160.440486"
                fill="#F2BD01"
                p-id="86078"
              ></path>
              <path
                d="M1047.630769 1024H148.003239a64.673684 64.673684 0 0 1-63.42996-54.723887L7.876923 456.446964a64.259109 64.259109 0 0 1 63.42996-73.794332h1053.020243a64.259109 64.259109 0 0 1 64.259109 73.794332L1111.060729 969.276113a64.673684 64.673684 0 0 1-63.42996 54.723887z"
                fill="#FFD02F"
                p-id="86079"
              ></path>
            </svg>
            <span class="case_name" @click="clickItem(item, index)"
              >&nbsp;{{ item.name }}</span
            >
            <div
              class="icon-success"
              :style="{
                background:
                  (selectItemIndex || selectItemIndex === 0) &&
                  index === selectItemIndex
                    ? '#409eff'
                    : '#c8c9cc'
              }"
            >
              <i
                class="van-icon van-icon-success"
                @click="clickItem(item, index)"
              ></i>
            </div>
          </div>
        </div>
        <fb-loading :loading="isload" v-if="isload" />
      </div>
    </div>
    <div class="relate-foot">
      <van-button class="cancel-btn" size="small" @click="handleCancel"
        >取&nbsp;&nbsp;消</van-button
      >
      <van-button type="info" class="save-btn" size="small" @click="handleSave"
        >保&nbsp;&nbsp;存</van-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'relateCase',
  props: {
    type: {
      type: String,
      default: 'case'
    },
    reportName: {
      type: String,
      default: ''
    },
    tempTitle: {
      type: String,
      default: '临时检索报告'
    }
  },
  data() {
    return {
      changeName: false, // 是否改名
      currentName: '',
      relateCaseList: [],
      pageNum: 0,
      search: '',
      isload: false,
      more: true,
      selectItem: null,
      selectItemIndex: null
    }
  },
  computed: {
    ...mapGetters('user', ['userId']),
    placeholder() {
      return this.type === 'case'
        ? '请选择或输入关联案件'
        : '请选择或输入目标文件'
    },
    saveMessage() {
      if (this.type === 'case') {
        return '关联下列案件'
      } else if (this.type === 'folder') {
        return '保存到下面路径'
      } else {
        return ''
      }
    }
  },
  mounted() {
    if (this.userId) {
      this.getcaselist()
    }
    this.currentName =
      this.reportName !== '' && this.reportName !== '临时检索报告'
        ? this.reportName
        : this.tempTitle
    console.log(this.reportName, '++++++mounted----', this.tempTitle)
  },
  methods: {
    clickChangeName() {
      this.changeName = true
      setTimeout(() => {
        if (this.$refs.changNameInput) {
          this.$refs.changNameInput.focus()
        }
      }, 200)
    },
    // 确认改名
    handleSureChange() {
      this.changeName = false
      this.$emit('changeName', this.currentName)
    },
    handleSearch() {
      this.pageNum = 0
      this.getcaselist(this.search.trim(), true)
    },
    handleCancel() {
      this.selectItem = null
      this.selectItemIndex = null
      this.$emit('cancel')
    },
    handleSave() {
      this.$emit('select', this.selectItem)
    },
    clickItem(item, index) {
      if (this.selectItemIndex === index) {
        this.selectItemIndex = null
        this.selectItem = null
      } else {
        this.selectItemIndex = index
        this.selectItem = item
      }
      // console.log(index, this.selectItemIndex, '-----')
    },
    getcaselist(val, flag) {
      this.isload = true
      console.log('getcaselist', val, flag)
      if (!val) {
        val = ''
      }
      let url = ''
      if (this.type === 'case') {
        url = `${this.$base}/management/user/${
          this.userId
        }/collection?level=0&page=${this.pageNum}&hasCase=0&name=${val}`
      } else if (this.type === 'folder') {
        url = `${this.$base}/management/user/${
          this.userId
        }/collection?level=0&page=${this.pageNum}&type=FOLDER&name=${val}`
      }
      this.$axios
        .get(url)
        .then(res => {
          if (flag) {
            this.relateCaseList = []
          }
          if (res.data.code === 200) {
            this.relateCaseList = this.relateCaseList.concat(
              res.data.data.content
            )
            this.isload = false
            this.more = !res.data.data.last
          }
        })
        .catch(err => {
          Promise.reject(err)
          this.isload = false
        })
    },
    handleScroll(e) {
      // console.log(
      //   'scroll----------',
      //   Math.floor(e.target.scrollTop + e.target.offsetHeight + 10),
      //   e.target.scrollHeight
      // )
      if (
        Math.floor(e.target.scrollTop + e.target.offsetHeight + 10) >=
          e.target.scrollHeight &&
        this.more
      ) {
        console.log('加载更多---')
        this.pageNum++
        this.getcaselist(this.search.trim())
      }
    }
  },
  watch: {
    search(val) {
      this.pageNum = 0
      this.getcaselist(val.trim(), true)
    },
    // 当前报告的名字
    reportName() {
      this.currentName =
        this.reportName !== '' ? this.reportName : this.tempTitle
    },
    userId(val) {
      // console.log(val, '------------------isload-----------------------')
      this.getcaselist()
    }
  }
}
</script>

<style lang="stylus" scope>
.relate
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  z-index 2998
  background #fff
.relate-head
  position absolute
  top 0
  left 0
  width 100%
  height 40px
  line-height 40px
  z-index 3000
  // background red
  display flex
  font-size 14px
  .file-name
    color #409eff
.relate-foot
  position absolute
  bottom 0
  left 0
  width 100%
  height 40px
  line-height 40px
  z-index 3000
  // background red
  display flex
  justify-content space-around
  align-items center
  font-size 14px
  border-top 1px solid #ccc
  .cancel-btn
    background #ccc
    border-color #ccc
    color #fff
    padding 10px 40px
  .save-btn
    padding 10px 40px
.content-wrapper
  width 100vw
  height calc(100vh - 80px)
  margin-top 40px
  // background green
  overflow scroll
.content-list
  width 100%
  min-height calc(100vh - 80px)
  // background #fff
  .search-head
    display flex
    background #F5F5F5
    .right-icon
      padding-left 20px
    .select-name
      min-height 46px
      line-height 46px
      font-size 14px
      padding-left 15px
      color #323233
    .van-cell
      background none
    .cancel
      width 80px
      text-align center
      font-size 14px
      color #ccc
    .iconfont
      width 50px
  .content-item
    line-height 1.333rem
    padding 0 10px
    .item_name
      display flex
      align-items center
      justify-content space-between
      .case_name
        flex 1
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
    .icon-success
      width 20px
      height 20px
      margin-right 20px
      border-radius 50%
      font-size 14px
      text-align center
      line-height 24px
      color #fff
</style>
