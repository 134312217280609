<template>
  <div class="edit-content">
    <div id="editorId"></div>
    <div v-for="item in judgeList" :key="item.id" class="report-item">
      <h2 v-html="item.title"></h2>
      <span v-html="item.sub_title"></span>
      <div v-for="(ite, ind) in item.content" :key="ind">
        <h3>
          {{ ite.name }}
        </h3>
        <p v-for="(it, i) in ite.value" :key="i">{{ it }}</p>
        <!-- <h3 v-if="typeof ite.value === 'string' || ite.value.length">
          {{ ite.name }}
        </h3>
        <p v-if="typeof ite.value === 'string'">{{ ite.value }}</p>
        <p v-else v-for="(it, i) in ite.value" :key="i">{{ it }}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import EditorJS from '@editorjs/editorjs'
// import Header from '@editorjs/header'
// import List from '@editorjs/list'
export default {
  data() {
    return {
      judgeList: [],
      initData: {
        // time: 1550476186479,
        blocks: [
          // {
          //   type: 'header',
          //   data: {
          //     text: 'Editor.js',
          //     level: 2
          //   }
          // },
          // {
          //   type: 'paragraph',
          //   data: {
          //     text:
          //       'Hey. Meet the new Editor. On this page you can see it in action — try to edit this text. Source code of the page contains the example of connection and configuration.'
          //   }
          // },
          // {
          //   type: 'header',
          //   data: {
          //     text: 'Key features',
          //     level: 3
          //   }
          // },
          // {
          //   type: 'list',
          //   data: {
          //     style: 'unordered',
          //     items: [
          //       'It is a block-styled editor',
          //       'It returns clean data output in JSON',
          //       'Designed to be extendable and pluggable with a simple API'
          //     ]
          //   }
          // },
          // {
          //   type: 'header',
          //   data: {
          //     text: 'What does it mean «block-styled editor»',
          //     level: 3
          //   }
          // },
          // {
          //   type: 'paragraph',
          //   data: {
          //     text:
          //       'Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class="cdx-marker">workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor\'s Core.'
          //   }
          // }
        ]
        // version: '2.8.1'
      }
    }
  },
  computed: {
    ...mapGetters('searchReport', ['reportCount']),
    ...mapState('searchReport', [
      'judgeReports',
      'lawReports',
      'contentReports',
      'textReport',
      'anaReports',
      'knowledgeReports',
      'criterionReports'
    ])
  },
  created() {
    console.log('created---->', this.judgeReports)
    this.judgeList = this.judgeReports.map(item => {
      const contentArr = JSON.parse(JSON.stringify(item.content))
      contentArr.forEach((ite, i) => {
        if (typeof ite.value === 'string') {
          ite.value = [ite.value]
        }
      })
      return {
        title: `${(item.case_attr === '普通案例'
          ? ''
          : '<span style="color:red;margin-right:6px;">' +
            item.case_attr +
            '</span>') + item.case_name}`,
        sub_title: `<span class="sub-title">${(item.court_name
          ? item.court_name
          : '') +
          (item.case_id ? ' / ' + item.case_id : '') +
          (item.pub_date ? ' / ' + item.pub_date : '')}</span>`,
        id: item.id,
        doc_id: item.doc_id,
        content: contentArr.filter(v => v.value.length > 0)
      }
    })
    console.log('created---->', this.judgeReports, this.judgeList)
    this.initData.blocks = []
    this.judgeList.forEach(item => {
      const reportTitle = {
        type: 'header',
        data: {
          text: item.title,
          level: 2
        }
      }
      const reportSubTitle = {
        type: 'header',
        data: {
          text: item.sub_title,
          level: 4
        }
      }
      // const reportContent = {
      //   type: 'list',
      //   data: {
      //     style: 'unordered',
      //     items: item.content.map(ite => ite.value)
      //   }
      // }
      this.initData.blocks.push(reportTitle)
      this.initData.blocks.push(reportSubTitle)
      // this.initData.blocks.push(reportContent)
      item.content.forEach(ite => {
        const reportContentTitle = {
          type: 'header',
          data: {
            text: ite.name,
            level: 3
          }
        }
        const reportContentValue = {
          type: 'list',
          data: {
            style: 'unordered',
            items: ite.value
          }
        }
        this.initData.blocks.push(reportContentTitle)
        this.initData.blocks.push(reportContentValue)
      })
    })
  },
  mounted() {
    /* const editor = new EditorJS({
      holder: 'editorId',
      data: this.initData,
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link']
        },
        list: {
          class: List,
          inlineToolbar: true
        }
      }
    }) */
  }
}
</script>

<style lang="stylus" scope>
.edit-content
  padding 10px
  #editorId
    min-height calc(100vh - 50px)
  .report-item
    padding-bottom 15px
  h2
    font-size 14px
    font-weight bold
    line-height 20px
    &>span
      font-size 14px
      font-weight bold
  span,.sub-title
    font-size 10px
    line-height 20px
    color #ccc
    font-weight normal
  h3
    font-size 12px
    font-weight bold
    color #666
  p
    font-size 12px
    color #666
    line-height 20px
  .cdx-list
    padding 0px 10px
  li
    list-style none
    line-height 18px
  .cdx-list-settings
    display none
</style>
