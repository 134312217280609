var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { "mul-item-wrapper": true, "bold-item": true } }, [
    _vm.type === "judge"
      ? _c("span", {
          domProps: { innerHTML: _vm._s(_vm.item.case_name) },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleSwith.apply(null, arguments)
            },
          },
        })
      : _vm.type === "criterion"
      ? _c("span", {
          domProps: { innerHTML: _vm._s(_vm.item.stdTitle || _vm.item.title) },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleSwith.apply(null, arguments)
            },
          },
        })
      : _vm.type === "agreement"
      ? _c("span", {
          domProps: { innerHTML: _vm._s(_vm.item.title) },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleSwith.apply(null, arguments)
            },
          },
        })
      : _c("span", {
          domProps: { innerHTML: _vm._s(_vm.item.title) },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleSwith.apply(null, arguments)
            },
          },
        }),
    _c("i", {
      staticClass: "iconfont icon-clear-2",
      on: { click: _vm.handleDelItem },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }