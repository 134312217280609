var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-content" },
    [
      _c("div", { attrs: { id: "editorId" } }),
      _vm._l(_vm.judgeList, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "report-item" },
          [
            _c("h2", { domProps: { innerHTML: _vm._s(item.title) } }),
            _c("span", { domProps: { innerHTML: _vm._s(item.sub_title) } }),
            _vm._l(item.content, function (ite, ind) {
              return _c(
                "div",
                { key: ind },
                [
                  _c("h3", [_vm._v(" " + _vm._s(ite.name) + " ")]),
                  _vm._l(ite.value, function (it, i) {
                    return _c("p", { key: i }, [_vm._v(_vm._s(it))])
                  }),
                ],
                2
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }