<template>
  <div class="history-wraper" @scroll="handleScroll">
    <div v-if="changeReport">
      <van-field
        v-model="changeReportName"
        clearable
        @keydown.enter.native="handleKeyEnter"
      />
    </div>
    <div v-else>
      <div class="van-action-sheet__item van-hairline--top history_title">
        <div>
          <span>历史记录&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span class="switch-collect" @click="emitSwitch" v-show="reportId"
            >新收集</span
          >
        </div>
        <span
          style="color:#409eff"
          v-show="reportList.length > 0"
          @click="handleClear"
          >清空</span
        >
      </div>
      <div
        v-for="(item, index) in reportList"
        :key="index"
        class="van-action-sheet__item van-hairline--top item"
      >
        <div
          class="van-action-sheet__name history_item"
          :class="reportId === item.id ? 'activeClass' : ''"
        >
          <div class="left">
            <div class="up">
              <span class="index">{{ index + 1 }}&nbsp;&nbsp;</span>
              <span class="name">{{
                item.name && item.name.length > 20
                  ? item.name.substr(0, 20) + '...'
                  : item.name
              }}</span>
            </div>
            <div class="down">
              <span class="name">{{
                item.label
                  ? item.label.length > 16
                    ? item.label.substr(0, 16) + '...'
                    : item.label
                  : '暂无关联案件'
              }}</span>
            </div>
          </div>
          <div class="middle">
            <i class="iconfont icon-edit" @click="handChangName(item)"></i>
          </div>
          <div class="right">
            <span @click="handleDel(item, index)">删除</span>
            <span v-show="reportId !== item.id" @click="handleOpen(item)"
              >打开</span
            >
          </div>
        </div>
      </div>
      <fb-loading :loading="showLoading" v-if="showLoading" />
    </div>
    <!-- 全部清空 -->
    <van-dialog
      v-model="showClearDelDialog"
      title=""
      @confirm="clearConfirm"
      show-cancel-button
      :overlay="true"
    >
      <div class="van-dialog__content">
        <div class="van-dialog__message">
          确定删除当前页面所有的检索报告？
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'history',
  props: ['reportId'],
  data() {
    return {
      reportList: [],
      pageNum: 0,
      more: true,
      showClearDelDialog: false,
      totalElements: 0,
      showLoading: false,
      changeReport: null, // 选中改名的报告
      changeReportName: '', // 要改的名字
    }
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  methods: {
    // 改名
    async handleKeyEnter() {
      const d = {
        name: this.changeReportName,
        type: 'SEARCHREPORT',
        id: this.changeReport.id,
      }
      try {
        await this.$axios.post(
          `${this.$base}/management/collection/updateFileInfos`,
          {
            ...d,
          }
        )
        this.changeReport = null
        this.reportList = []
        this.getList()
      } catch (error) {
        console.log(error)
      }
    },
    emitSwitch() {
      this.$emit('switch')
    },
    handleClear() {
      this.showClearDelDialog = true
    },
    async clearConfirm() {
      this.showClearDelDialog = false
      this.showLoading = true
      try {
        const url = `${this.$base}/management/user/${
          this.userId
        }/collection/withoutContent?page=0&type=SEARCHREPORT&&isShow=1&size=${
          this.totalElements
        }`
        const res1 = await this.$axios.get(url)
        if (res1.data.code === 200) {
          const ids = res1.data.data.content
            .filter(item => item.isDeletable)
            .map(v => {
              return { id: v.id }
            })
          const res2 = await this.$axios({
            url: `${this.$base}/management/user/${this.userId}/collection`,
            method: 'delete',
            data: ids,
            header: {
              'Content-Type': 'application/json;charset=utf-8',
            },
          })
          if (res2.data.code === 200) {
            this.reportList = []
            this.showLoading = false
          }
        }
      } catch (error) {
        console.log(error)
        this.showLoading = false
        this.showClearDelDialog = false
      }
    },
    getList(val) {
      console.log('getlist==========')
      this.showLoading = true
      if (!val) {
        val = ''
      }
      const url = `${this.$base}/management/user/${
        this.userId
      }/collection/withoutContent?page=${
        this.pageNum
      }&type=SEARCHREPORT&name=${val}&isShow=1&size=15`
      this.$axios
        .get(url)
        .then(res => {
          if (res.data.code === 200) {
            this.totalElements = res.data.data.totalElements
            const content = res.data.data.content.filter(
              item => item.isDeletable
            )
            content.forEach(v => {
              v.reportName = v.name === '临时检索报告' ? '未命名' : v.name
            })
            this.reportList = this.reportList.concat(content)
            this.more = !res.data.data.last
          }
          this.showLoading = false
        })
        .catch(err => {
          Promise.reject(err)
          this.showLoading = false
        })
    },
    handChangName(item) {
      this.changeReport = item
      this.changeReportName = item.name
    },
    handleDel(item, index, ids) {
      const arr = [{ id: item.id }]
      this.showLoading = true
      this.$axios({
        url: `${this.$base}/management/user/${this.userId}/collection`,
        method: 'delete',
        data: arr,
        header: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      }).then(res => {
        if (res.data.code === 200) {
          // this.getList()
          this.showLoading = false
          this.reportList.splice(index, 1)
          if (this.reportId === item.id) {
            this.$emit('init')
          }
        }
      })
    },
    handleOpen(item) {
      this.$emit('open', item)
    },
    handleScroll(e) {
      if (
        Math.floor(e.target.scrollTop + e.target.offsetHeight + 10) >=
          e.target.scrollHeight &&
        this.more &&
        !this.showLoading
      ) {
        console.log('加载更多')
        this.pageNum++
        this.getList()
      }
    },
  },
  created() {
    if (this.userId) {
      this.getList()
    }
  },
  watch: {
    userId(val) {
      this.getList()
    },
    reportId() {
      this.reportList = []
      this.getList()
    },
  },
}
</script>

<style lang="stylus" scope>
.history-wraper
  width 100%
  max-height 80vh
  overflow scroll
  .history_item
    display flex
    justify-content space-between
    align-items center
    padding 0 10px
    height 40px
    .left
      flex 1
      display flex
      flex-direction column
      font-size 12px
      max-width calc(100% - 120px)
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
      word-break keep-all
      .up
        height 20px
        line-height 20px
      .down
        color #ccc
        height 20px
        line-height 20px
    .middle
      width 30px
      display flex
      justify-content center
      align-item center
      font-size 18px
      margin-right 12px
      line-height 40px
    .right
      width 60px
      display flex
      color #409eff
      font-size 12px
      line-height 40px
      display flex
      justify-content space-between
      align-items center
  & .activeClass
    font-weight bold
  .history_title
    display flex
    justify-content space-between
    align-items center
    padding 0 10px
    height 40px
    font-size 12px
    line-height 40px
    .span
      font-size 12px
      color #409eff
    .switch-collect
      color #409eff
</style>
