<template>
  <div :class="{ 'mul-item-wrapper': true, 'bold-item': true }">
    <!-- <van-checkbox
      v-model="item.checked"
      @change="handleChange('contentList')"
      shape="square"
    ></van-checkbox>
    &nbsp;&nbsp;-->
    <span
      v-if="type === 'judge'"
      v-html="item.case_name"
      @click.stop="handleSwith"
    ></span>
    <span
      v-else-if="type === 'criterion'"
      v-html="item.stdTitle || item.title"
      @click.stop="handleSwith"
    ></span>
    <span
      v-else-if="type === 'agreement'"
      v-html="item.title"
      @click.stop="handleSwith"
    ></span>
    <span v-else v-html="item.title" @click.stop="handleSwith"></span>
    <i class="iconfont icon-clear-2" @click="handleDelItem"></i>
  </div>
</template>

<script>
export default {
  props: ['item', 'idName', 'currentIdName', 'type', 'index'],
  data() {
    return {
      checked: false,
    }
  },
  mounted() {
    this.checked = this.checkedAll
  },
  methods: {
    handleSwith() {
      this.$emit('switch', {
        idName: this.idName,
        currentIdName: this.currentIdName,
      })
    },
    handleDelItem() {
      this.$emit('delItem', {
        listType: this.type + 'List',
        reportType: this.type + 'Reports',
        showConfirm: false,
        index: this.index,
      })
    },
    handleChange() {
      console.log('------')
      this.$emit('changeItemCheck', this.type)
    },
  },
}
</script>

<style scope lang="stylus">
.mul-item-wrapper
  color #000000
  font-weight normal
  em
    color #000000
    font-weight normal
&.bold-item
  font-weight bold
  em
    font-weight bold
.active-title
  .mul-item-wrapper
    color #409eff
  em
    color #409eff
</style>
