var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relate" }, [
    _c(
      "div",
      { staticClass: "relate-head" },
      [
        _c("i", {
          staticClass: "iconfont icon-back",
          on: { click: _vm.handleCancel },
        }),
        _vm.changeName
          ? _c("van-field", {
              ref: "changNameInput",
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleSureChange.apply(null, arguments)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "right-icon",
                    fn: function () {
                      return [
                        _c("span", { on: { click: _vm.handleSureChange } }, [
                          _vm._v("确认"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                466409524
              ),
              model: {
                value: _vm.currentName,
                callback: function ($$v) {
                  _vm.currentName = $$v
                },
                expression: "currentName",
              },
            })
          : _c("span", [
              _vm._v("  将"),
              _c(
                "span",
                {
                  staticClass: "file-name",
                  on: { click: _vm.clickChangeName },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.currentName.length > 20
                          ? _vm.currentName.substr(0, 20) + "..."
                          : _vm.currentName
                      ) +
                      " "
                  ),
                ]
              ),
              _vm._v(_vm._s(_vm.saveMessage)),
            ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content-wrapper", on: { scroll: _vm.handleScroll } },
      [
        _c(
          "div",
          { staticClass: "content-list" },
          [
            _c(
              "div",
              { staticClass: "search-head" },
              [
                _vm.selectItem
                  ? _c("div", { staticClass: "select-name" }, [
                      _c("span", [_vm._v("首页 / ")]),
                      _c("span", [_vm._v(_vm._s(_vm.selectItem.name))]),
                    ])
                  : _c("van-field", {
                      attrs: { placeholder: _vm.placeholder, clearable: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "right-icon",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "iconfont icon-search right-icon",
                                on: { click: _vm.handleSearch },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
              ],
              1
            ),
            _vm._l(_vm.relateCaseList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "van-action-sheet__item van-hairline--top content-item",
                },
                [
                  _c(
                    "div",
                    { staticClass: "van-action-sheet__name item_name" },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: {
                            t: "1607483501714",
                            viewBox: "0 0 1196 1024",
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                            "p-id": "86077",
                            width: "30",
                            height: "30",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M69.234008 425.768421L62.186235 128.518219a64.259109 64.259109 0 0 1 64.259109-66.331984h258.280162a64.673684 64.673684 0 0 1 44.774089 18.241295l144.272065 142.199191h497.489878a63.844534 63.844534 0 0 1 63.42996 63.844534v160.440486",
                              fill: "#F2BD01",
                              "p-id": "86078",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              d: "M1047.630769 1024H148.003239a64.673684 64.673684 0 0 1-63.42996-54.723887L7.876923 456.446964a64.259109 64.259109 0 0 1 63.42996-73.794332h1053.020243a64.259109 64.259109 0 0 1 64.259109 73.794332L1111.060729 969.276113a64.673684 64.673684 0 0 1-63.42996 54.723887z",
                              fill: "#FFD02F",
                              "p-id": "86079",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "case_name",
                          on: {
                            click: function ($event) {
                              return _vm.clickItem(item, index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "icon-success",
                          style: {
                            background:
                              (_vm.selectItemIndex ||
                                _vm.selectItemIndex === 0) &&
                              index === _vm.selectItemIndex
                                ? "#409eff"
                                : "#c8c9cc",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "van-icon van-icon-success",
                            on: {
                              click: function ($event) {
                                return _vm.clickItem(item, index)
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            _vm.isload
              ? _c("fb-loading", { attrs: { loading: _vm.isload } })
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "relate-foot" },
      [
        _c(
          "van-button",
          {
            staticClass: "cancel-btn",
            attrs: { size: "small" },
            on: { click: _vm.handleCancel },
          },
          [_vm._v("取  消")]
        ),
        _c(
          "van-button",
          {
            staticClass: "save-btn",
            attrs: { type: "info", size: "small" },
            on: { click: _vm.handleSave },
          },
          [_vm._v("保  存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }